.modal-content {
    background-clip: padding-box;
}

.modal {
    &.fade.upslide .modal-dialog {
        transform: translate3d(0, 100vh, 0);
    }

    &.show.upslide .modal-dialog {
        transform: translate3d(0, 0, 0);
    }
}

.modal-dialog {
    margin-left: auto;
    margin-right: auto;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    border-top: $modal-border-top;
    box-sizing: content-box;

    .modal-title {
        font-size: $h6-font-size;
        font-weight: $font-weight-normal;
        letter-spacing: $letter-spacing-sm;
        line-height: $line-height-xs;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        padding: $modal-header-padding-xl;
    }

    .close {
        width: $modal-header-size;
        height: $modal-header-size;
        position: absolute;
        padding: 0;
        right: $modal-header-close-position;
        top: $modal-header-close-position;
        opacity: 1;
        z-index: 2;

       
    }
}

.modal-body {
    padding: $modal-inner-padding;

    @include media-breakpoint-up(md) {
        padding: $modal-inner-padding-xl;
    }
}

.modal-footer {
    gap: map-get($spacers, 2);
    padding: $modal-footer-padding;

    > * {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        gap: map-get($spacers, 6);
        padding: $modal-footer-padding-xl;
    }
}


.sizing-modal {
    max-width: 600px !important;

    @include media-breakpoint-down(sm) {
        max-width: 100% !important;
    }

    .modal-footer {
        gap: 0;
        display: block;
    }

    .btn {
        width: 46% !important;
        border-radius: 0;
        padding: 5px !important;
    }

    .btn:first-child {
        margin: 0 rem(40px) 0 0;

        @include media-breakpoint-down(sm) {
            margin: 0 rem(25px) 0 0;
        }
    }

    .modal-content {
        border-radius: 0;
    }

    .modal-p-bold {
        font-weight: bold;
    }

    .modal-top-logos {
        transition: max-width 0.25s;
        display: inline-flex;

        img {
            display: block;
            height: 2.375rem;
            float: left;
            padding: rem(0px) rem(20px) rem(0px) rem(0px);
            margin-bottom: rem(20px);

            @include media-breakpoint-down(sm) {
                height: 2rem;
            }
        }

        img:nth-child(2) {
            padding-left: rem(20px);
        }

        img:first-child {
            border-right: 2px solid #404040;
        }
    }
}

.isModalclosed{
    display: none;
}

