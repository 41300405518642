.btn {
    border-radius: $btn-border-radius;
    letter-spacing: 0.8px;

    &.btn-secondary {
        &:focus {
            color: $white;
            background-color: $btn-secondary-color-bg;
            border-color: $gray-500;
            box-shadow: $btn-focus-box-shadow !important;
        }
    }

    &.btn-primary,
    &.btn-outline-primary,
    &.btn-secondary,
    &.checkout-btn,
    &.btn-continue-shopping,
    &.checkout-as-guest,
    &.submit-shipping,
    &.selectedAdress,
    &.place-order,
    &.btn-secondary-size {
        &:not(.btn-sm) {
            padding: rem(14px) rem(30px);
        }
    }

    &:not(:disabled):not(.disabled):not(.btn-icon--action):not(.btn-link):not(.btn-custom):not(.btn-outline-primary):not(.btn-secondary):not(.dropdown-toggle) {
        &:focus {
            color: $btn-main-color;
            background-color: $btn-primary-color-bg;
            border-color: $btn-primary-color-bg;
            box-shadow: $btn-focus-box-shadow;
        }
    }
}

// Custom disabled states for buttons
.btn-primary {
    color: $btn-primary-color;
    border-color: $btn-primary-color-bg;
    background-color: $btn-primary-color-bg;
    text-transform: uppercase;

    svg {
        fill: $btn-primary-color;

        &.icon-arrowRight {
            margin-top: rem(-1px);
        }
    }

    
    &:not(:disabled):not(.disabled) {
        .hover,
        .active,
        &:active,
        &:hover {
            background: $btn-primary-hover-color-bg;
        }
    }

    // bootstrap override for disabled style
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        background: $btn-disabled-main-bg;
        border-color: $btn-disabled-main-border-color;
        color: $btn-disabled-main-color;

        svg {
            fill: $btn-disabled-main-color;
        }
    }
}

.btn {
    &.btn-outline-primary {
        border-color: $btn-outline-border-color;
        color: $btn-outline-color;
        text-transform: uppercase;

        svg {
            fill: $btn-outline-color;
        }

        &:not(:disabled):not(.disabled) {
            &.hover,
            &.active,
            &.focus,
            &:active,
            &:hover,
            &:focus {
                border-color: $btn-outline-border-color;
                box-shadow: none;
                color: $btn-outline-color;
                background-color: $btn-outline-color-bg;
                text-decoration: underline;

                svg {
                    fill: $btn-outline-color;
                }
            }
        }

        // bootstrap override for disabled style
        &.disabled,
        &:disabled {
            background: $btn-disabled-main-bg;
            border-color: $btn-disabled-main-border-color;
            color: $btn-disabled-main-color;
            opacity: $btn-disabled-opacity;
            text-decoration: none;
        }
    }
}

.btn-secondary {
    border-color: $btn-secondary-color-bg;
    background-color: $btn-secondary-color-bg;
    text-transform: uppercase;

    &:not(:disabled):not(.disabled) {
        .hover,
        .active,
        &:active,
        &:hover {
            border-color: $btn-secondary-hover-color-bg;
            background-color: $btn-secondary-hover-color-bg;
        }
    }

    // bootstrap override for disabled style
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        background: $btn-disabled-alt-bg;
        border-color: $btn-disabled-alt-border-color;
        color: $btn-disabled-alt-color;
    }
}

.btn-icon {
    position: relative;
    flex-wrap: nowrap;
    align-items: center;

    svg {
        margin: -2px 6px 0 0;
    }

    &--action {
        background-color: $white;
        border-color: $black;
        text-transform: uppercase;

        &:not(:disabled):not(.disabled) {
            &:focus,
            &:active,
            &.active {
                color: $body-color;
                background-color: $white;
                border-color: $black;
                box-shadow: $btn-focus-box-shadow;
            }
        }
    }
}

// Button text link
.btn-link {
    border: 0;
    border-radius: 0;
    color: $btn-outline-color;
    letter-spacing: normal;
    padding: 0;
    text-transform: initial;
    vertical-align: initial;

    &:hover {
        color: $btn-outline-color;
    }

    &.btn:not(:disabled):not(.disabled),
    &:not(:disabled):not(.disabled) {
        &:focus,
        &:active,
        &.active {
            box-shadow: none;
        }
    }
}

button:focus {
    outline-style: none;
}
