.pagination {
    @include media-breakpoint-down(md) {
        &.pagination-lg .page-link {
            padding: map-get($spacers, 2) map-get($spacers, 3);
            line-height: $line-height-sm;
        }
    }

    @include border-radius($border-radius-xs);
    gap: $spacer / 3;
    margin-bottom: 0;

    &:not(.list-group) li {
        margin-bottom: 0;
    }

    .page-item {
        .page-link {
            align-items: center;
            border-color: $gray-500;
            @include border-radius($border-radius-xs);
            color: $black;
            display: flex;
            height: 100%;
            position: relative;

            &:hover {
                background-color: $white;
                border-color: $gray-600;
            }

            &:focus {
                box-shadow: none;
            }

            .icon {
                display: block;
                height: rem(12px);
            }
        }

        &.active {
            .page-link {
                background-color: $black;
                border-color: $black;
                color: $white;
                pointer-events: none;
            }
        }

        &.disabled {
            .page-link {
                background-color: $gray-400;
                color: $gray-400;

                .icon {
                    opacity: 0.25;
                }
            }
        }
    }
}