.order-history-page {
    .account-content-wrapper {
        max-width: rem(820px);
    }

    .account-title-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: left;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        @include media-breakpoint-up(md) {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }

    .order-history-select-wrappper {
        width: 100%;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 16px;
            top: 0;
            width: auto;
        }
    }

    .account-page-title {
        font-size: 1.375rem;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    .account-back-link {
        font-size: .875rem;

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 1rem;
            bottom: 0.5rem;
            font-size: 1rem;
        }
    }

    .card--has-bg-color {
        background-color: $gray-400;
    }

    .order-history-divisor {
        border-bottom: rem(1px) solid $gray-500;

        @include media-breakpoint-up(md) {
            border-bottom: none;
            border-right: rem(1px) solid $gray-500;
        }
    }
    .order-history-labels {
        
        @include media-breakpoint-up(md) {
            max-width: rem(150px);
        }
    }
}