.login-area {
    .card {
        .card-subtitle {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            .btn-primary {
                max-width: rem(360px);
            }
        }

        .form-check-input {
            margin: 0;
        }

        .forgot-password {
            line-height: $custom-control-indicator-size;
        }
    }
}


.login-area,
.signup-area {
    .account-page-title {
        font-size: 1.375rem;
        font-weight: 600;
        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    .card {
        padding: 0;

        @include media-breakpoint-up(md) {
            padding: rem(18px) rem(10px) rem(10px);
        }
    }

    .card-header {
        h2 {
            font-size: 1.5rem;
            margin-bottom: 0;

            &.card-title {
                font-weight: 600;
                line-height: 2.125rem;
            }
        }
    }

    .show-password {
        position: absolute;
        top: calc(50% - 14px);
        right: 0.9375rem;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .invalid {
        top: calc(50% - 24px);
    }
}

.signup-area {
    .custom-checkbox {
        .custom-control-label {
            color: #404040;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25rem;
        }
        .form-text {
            font-size: .875rem;
            line-height: 1.43;
            margin: 0;
        }
    }
}
