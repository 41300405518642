@mixin productFit {
  .product-fit {
    gap: rem(8px);

    .btn {
      flex: 1;
      font-size: $font-size-base;
      font-weight: $font-weight-base;
      letter-spacing: 0;
      max-width: 33%;
      text-transform: capitalize;

      &.btn-sm {
        padding-bottom: $spacer / 2;
        padding-top: $spacer / 2;
      }

      &.btn-outline-primary {
        color: $gray-800;
        border-color: $gray-600;

        &:not(:disabled):not(.disabled) {
          &.active,
          &:active,
          &:hover,
          &:focus {
            color: $gray-800;
            text-decoration: none;
          }

          &.active {
            background-color: $black;
            color: $white;
          }
        }
      }
    }
  }
}
