.container,
.container-lg,
.container-xl,
.container-fluid {
    padding-left: $container-padding-xs;
    padding-right: $container-padding-xs;
    max-width: map-get($container-max-widths, xl); // Adding on all containers from 0 to enforce fluid behavior

    @include media-breakpoint-up(md) {
        padding-left: $container-padding-md;
        padding-right: $container-padding-md;
    }

    @include media-breakpoint-up(lg) {
        padding-left: $container-padding-lg;
        padding-right: $container-padding-lg;
    }
}

@include media-breakpoint-between(xs, sm) {
    .row:not(.no-gutters) {
        @include make-row($grid-gutter-width-xs);
    }

    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
        padding-right: $grid-gutter-width-xs / 2;
        padding-left: $grid-gutter-width-xs / 2;
    }
}

// To be used when needing a full-width container (limited to site max) without any paddings, like banners
.container-full-size {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: auto;
    margin-right: auto;
    max-width: $content-max-width;
    width: 100%;
}
