@import "collapsibleItem";
@import "toastMessage";

footer {
    background-color: $gray-200;
    padding-top: map-get($spacers, 3);
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
        padding-bottom: 20px;
    }

    @include media-breakpoint-up(md) {
        padding-top: rem(56px);
    }

    h2 {
        font-size: $font-size-base;
        margin-bottom: 0;
        line-height: 2.5em;
        font-weight: $font-weight-bold;
        letter-spacing: normal;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 0;
        }
    }

    .social {
        h2 {
            margin-top: 0;
        }

        @include clearfix;
    }

    .social-links {
        @include clearfix;

        li {
            display: inline-block;
            margin-right: 1rem;

            a {
                width: rem(30px);
                height: rem(30px);
                display: inline-block;
                background-repeat: no-repeat;
                border-radius: 50%;
                background-color: $footer-social-bg;
                background-size: rem(15px) rem(15px);
                background-position: center center;

                &.facebook {
                    background-image: $svg-facebook;
                }

                &.instagramm {
                    background-image: $svg-instagramm;
                }

                &.pinterest {
                    background-image: $svg-footerPinterest;
                    background-size: 30px 30px;
                    background-color: $white;
                }

                &.tiktok {
                    background-image: $svg-tiktok;
                }
            }
        }
    }

    .store {
        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid $dark-gray;

            .content {
                display: none;
            }

            h2 {
                @include clearfix;
            }
        }
    }

    .footer-signup {
        .footer-left-column-inner {
            @include media-breakpoint-up(md) {
                max-width: 460px;
            }
        }

        .logo-footer {
            width: rem(320px);
        }

        h3 {
            font-size: $footer-signup-header;
            letter-spacing: normal;

            @include media-breakpoint-up(md) {
                font-size: rem(22px);
            }

            @include media-breakpoint-up(llg) {
                font-size: rem(24px);
            }
        }

        p {
            color: $dark;
            font-size: $footer-signup-text;
            line-height: 1.5;
            margin-bottom: map-get($spacers, 5);
        }

        .form-text {
            color: $dark;
        }

        .input-group {
            margin-bottom: map-get($spacers, 5);

            input {
                border-right: 0;
            }
        }

        form {
            margin-bottom: rem(23px);
        }

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            width: 40px;
            height: 55px;
            border: none;
            padding: 0;
            line-height: 0;
            z-index: 1;

            svg {
                fill: $footer-button-color;
                height: 17px;
                width: 8px;
            }

            &::before {
                content: "";
                height: rem(25px);
                width: 1px;
                background: $footer-button-border-color;
                position: absolute;
                left: 0;
                top: calc(50% - #{rem(12.5px)});
            }

            &:focus,
            &:active {
                box-shadow: none !important;
            }
        }

        .form-control {
            height: rem(55px);
            padding-right: 40px;

            &::placeholder {
                color: $gray-800;
            }
        }
    }
}

.back-to-top {
    margin: 0;
    padding: 0;
    border: 0;
    height: $btn-to-top-size-xs;
    width: $btn-to-top-size-xs;
    background: $btn-to-top-bg;
    color: $btn-to-top-color;
    border-radius: $btn-to-top-radius;
    display: inline-block;
    -webkit-appearance: none;
    position: fixed;
    right: 5px;
    bottom: 100px;
    z-index: 3;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 1.4px;

    @include media-breakpoint-up(md) {
        font-size: 14px;
        height: $btn-to-top-size-md;
        width: $btn-to-top-size-md;
    }

    span {
        display: block;
    }

    .icon-arrowUpWhite {
        aspect-ratio: 5 / 3;
        background-image: $svg-arrowUpWhite !important;
        background-repeat: no-repeat;
        background-size: 100%;
        margin: -4px auto 1px;
        height: 8px;

        @include media-breakpoint-up(md) {
            margin: -5px auto 3px;
            height: 12px;
        }
    }
}

.footer-container {
    @include media-breakpoint-down(sm) {
        flex: auto;
        border-top: 1px solid $gray-500;
        margin: auto (-$container-padding-xs);
        max-width: 100vw;
        overflow: hidden;
    }
}

.footer-secondary {
    padding-bottom: rem(40px);

    .copyright-notice {
        font-size: rem(11px);
        text-align: center;
        padding-bottom: rem(17px);
    }

    .footer-logos-container {
        @include media-breakpoint-down(xs) {
            img {
                max-width: 135px;
            }
        }
    }
}

.copyright-notice {
    color: $footer-copy-color;

    @include media-breakpoint-up(md) {
        font-size: rem(11px);
    }

    @include media-breakpoint-only(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.footer-item {
    li {
        a {
            font-size: $font-size-base * 0.875;
        }
    }

    @include media-breakpoint-down(sm) {
        &.collapsible-sm,
        .collapsible-sm {
            position: relative;

            &.active {
                margin-bottom: 0;
                padding-bottom: rem(16px);
            }

            &::after {
                content: "";
                border-bottom: 1px solid $gray-500;
                position: absolute;
                left: -$grid-gutter-width-xs;
                right: -$grid-gutter-width-xs;
                bottom: 0;
            }
        }

        .title {
            padding: rem(10px) 0;
            font-size: $font-size-base * 0.875;

            &::after {
                content: "";
                background-image: $svg-plus;
                background-size: 100%;
                background-repeat: no-repeat;
                width: 18px;
                height: 18px;
                display: inline-block;
                margin-top: 12px;
            }
        }

        .title[aria-expanded="true"] {
            &::after {
                content: "";
                background-image: $svg-minus;
                margin-top: 20px;
            }
        }
    }

    li {
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.footer-banner {
    background-color: $footer-banner-bg-color;
    padding: rem(15px) 0;

    .footer-carousel:not(.slick-initialized) {
        .footer-banner-info {
            display: none;
        }
    }

    .footer-banner-info {
        text-align: center;
        color: $footer-banner-color;
        font-weight: $font-weight-lighter;

        a {
            color: $footer-banner-color !important;
            text-transform: uppercase;
            font-size: rem(13px);
            letter-spacing: rem(0.33px);
        }

        h4 {
            font-size: rem(24px);
            font-weight: $font-weight-lighter;
            letter-spacing: normal;

            @include media-breakpoint-up(md) {
                font-size: rem(20px);
            }

            @include media-breakpoint-up(lg) {
                font-size: rem(22px);
            }
        }
    }

    .slick-track {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto;
    }

    .slick-arrow {
        opacity: 1;
        background: none;
        width: auto;

        &::before {
            border: none;
            filter: invert(1);
            transform: scale(1.5);
        }

        &.slick-prev::before {
            background: url('/assets/svg-icons/arrowLeft.svg') no-repeat center center;
            margin-left: 16px;
        }

        &.slick-next::before {
            background: url('/assets/svg-icons/arrowRight.svg') no-repeat center center;
            margin-right: 16px;
        }
    }

    .slick-slide {
        float: none;
        height: auto;

        @include media-breakpoint-up(md) {
            border-right: rem(1px) solid $footer-banner-border-color;
            padding: 0 20px;
            display: flex;
            justify-content: center;

            > div {
                display: flex;
                align-items: center;
            }
        }

        &:last-child {
            border-right: 0;
        }
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}

body .acsb-trigger.acsb-ready {
    display: none !important;
}

.acsb-trigger-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $accessi-be-blue;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
        margin: 20px 0;
    }

    svg {
        height: 34px;
        fill: $white;
    }
}

.subscribeSignupSuccess {
    #email-SignUp-Footer::placeholder {
        color: #69a832;
    }

    .subscribe-email-chef-success svg {
        width: 100% !important;
        height: 100% !important;
        fill: $white !important;
        background: #69a832;
    }
}
