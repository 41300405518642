.content-page {
    padding-bottom: rem(60px);

    @include media-breakpoint-up(md) {
        padding-bottom: rem(100px);
    }

    h1 {
        color: $black;

        @include media-breakpoint-down(sm) {
            color: $accent-1;
            font-size: rem(22px);
            letter-spacing: rem(0.44px);
        }
    }

    h4 {
        font-size: rem(18px);
        font-weight: $font-weight-medium;
        letter-spacing: normal;
        line-height: rem(24px);
    }

    main {
        a {
            max-width: 100%;
            word-break: break-all;
        }

        a:not(.btn-primary, .btn-outline-primary, .quickview, .btn-link, .nav-link) {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        p {
            color: $black;
        }
    }
}
