@import "menu";

.top-menu {
    position: relative;

    .header-links {

        a,
        button {
            font-size: $font-size-xs;
            margin-left: 1.5rem;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

header,
.modal-add-to-cart {
    &.simplified {
        position: sticky;
        top: -1px;
        background-color: $white;
        box-shadow: $shadow2;
        padding: 0;
        z-index: 10;

        @include media-breakpoint-up(md) {
            padding: rem(16px) 0;
        }

        .brand {
            @include media-breakpoint-up(md) {
                margin: auto auto auto 0;
            }
        }

        .header-help a {
            font-size: rem(14px);
            font-weight: $font-weight-prebold;

            @include media-breakpoint-up(md) {
                font-size: rem(18px);
            }
        }
    }

    .brand {
        align-items: center;
        display: flex;
        gap: rem(28px);
        line-height: 1;
        overflow: hidden;
        text-align: center;

        .logo-home {
            display: block;
        }

        img {
            max-height: rem(32px);
            max-width: 100%;
        }

        .ua-logo-home {
            position: relative;

            &::after {
                background-color: $black;
                content: '';
                position: absolute;
                right: rem(-14px);
                top: 0;
                bottom: 0;
                width: 1px;
            }

            .logo {
                display: none;
            }

            .logo-icon {
                display: block;
                max-height: rem(32px);
            }
        }

        @include media-breakpoint-up(md) {
            gap: rem(40px);

            img {
                max-height: rem(38px);
            }

            .ua-logo-home {
                width: auto;

                &::after {
                    right: rem(-20px);
                }

                .logo {
                    display: block;
                    height: rem(38px);
                }

                .logo-icon {
                    display: none;
                }
            }
        }
    }
}

.navbar-header {
    .user,
    .minicart,
    .navbar-toggler {
        height: auto;
    }

    .navbar-toggler {
        border: none;
        border-radius: 0;
        margin-right: rem(12px);
        padding: 3px 0;
        width: auto;
    }

    .user {
        position: relative;

        .link-nodecoration {
            height: rem(18px);
            overflow: hidden;
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        .user-message {
            line-height: rem(18px);
            font-size: $font-size-xs;
        }

        .popover {
            display: none;
            position: absolute;
            padding: 12px 19px;
            top: 100%;
            left: unset;
            right: 0;
            min-width: rem(140px);
            border: none;
            background-color: $gray-400;

            a {
                white-space: nowrap;
                margin-bottom: map-get($spacers, 1);

                &:hover {
                    text-decoration: underline;
                }
            }

            .nav {
                flex-direction: column;
            }

            .nav-item {
                display: block;
                margin-bottom: 2px;
            }

            @include media-breakpoint-down(sm) {
                left: auto;
                right: 0;
                transform: translateX(0);
            }
        }

        &:hover {
            .popover {
                display: block;
            }
        }
    }


    .minicart {
        margin-left: rem(12px);
        vertical-align: top;

        a.minicart-link {
            position: relative;

            &:hover {
                text-decoration: none;
            }
        }

        .minicart-quantity {
            line-height: rem(18px);
            font-size: $font-size-xs;
        }

        .minicart-quantity.bubble {
            background: #d00f1b;
            color: $white;
            font-size: rem(12px);
            border-radius: rem(8px) rem(8px) 0 rem(8px);
            padding: 0 rem(4px) rem(1px);
            position: absolute;
            top: rem(-8px);
            right: rem(10px);
            line-height: rem(15px);
            text-align: center;
            min-width: 1rem;
            font-weight: 600;
        }

    }
}

.header-banner {
    background-color: $brand-second;
    color: $brand-light;
    font-size: $font-size-xs;
    font-weight: $font-weight-lighter;

    strong {
        font-weight: $font-weight-normal;
    }

    .top-callout {
        padding: 10px 0;

        @include media-breakpoint-up(md) {
            padding: 8px 0;
        }
    }

    .top-callout-toggler {
        color: $white;
        font-size: rem(14px);
        font-weight: $font-weight-lighter;

        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    a:not(.btn) {
        color: $white !important;

        &:hover,
        &:active {
            color: $white !important;
        }
    }

    .top-content {
        position: relative;
    }

    .header-banner-close {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .icon-close {
        width: rem(24px);
        height: rem(24px);
    }
}

.header-container {
    align-items: center;
    min-height: rem(60px);
    padding-bottom: rem(16px);
    padding-top: rem(16px);

    @include media-breakpoint-up(md) {
        flex-wrap: wrap;
        padding-bottom: 0;
        padding-top: 0;
    }

    .brand {
        transition: max-width 0.25s;
    }

    .nav-first-actions {
        margin: 0 0 0 auto;
    }

    .search {
        align-items: center;
        background-color: $white;
        display: flex;
        flex: 1 1 100%;
        margin: rem(16px) 0 0 0;
        order: 3;
        transition: all 0.25s;

        @include media-breakpoint-up(md) {
            flex: 0;
            margin: 0 rem(16px) 0 auto;
            order: 0;

            .site-search {
                max-width: rem(398px);
                width: rem(398px);
            }
        }
    }

    .search-toggle {
        display: none;
        margin-right: rem(12px);

        .fa-search::before,
        .fa-close::before {
            content: "";
            width: rem(18px);
            height: rem(18px);
            display: block;
            background-size: 100%;
        }

        .fa-search {
            &::before {
                background: $svg-search center bottom no-repeat;
            }
        }

        .fa-close {
            &::before {
                background: $svg-close no-repeat;
            }
        }
    }

    .navbar-nav {
        flex: 0 0 100%;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
    }

    .main-menu {
        flex: 1 1 auto;
    }
}

.sticky-header {
    background-color: $white;
    top: -60px;
    transition: top 0.25s;
}

#top-menu {
    &.header-is-stuck {
        margin-bottom: 60px;
    }
}

// Overwrites logo size
header {
    .brand {
        @include media-breakpoint-up(md) {
            img {
                &.pace-university {
                    max-height: rem(80px);
                }

                &.veritas-veterinary-specialist-of-oc {
                    max-height: rem(96px);
                }
            }
        }
    }
}
// END overwrite

.is-stuck {
    position: sticky;
    top: -1px;
    box-shadow: $shadow2;
    z-index: 101;

    .header-container {
        flex-wrap: nowrap;
        max-width: $content-extra-width !important;

        .brand {
            img {
                max-height: rem(32px);
            }

            @include media-breakpoint-between(md, lg) {
                .ua-logo-home {
                    .logo {
                        display: none;
                    }
        
                    .logo-icon {
                        display: block;
                    }
                }
            }
        }

        .nav-first-actions {
            margin: 0 0 0 auto;

            @include media-breakpoint-up(md) {
                order: 3;
            }
        }

        .main-menu {        
            border-bottom: 0;

            // @include media-breakpoint-between(md, lg) {
            //     width: 100%;
            // }

            @include media-breakpoint-up(md) {
                width: auto;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .dropdown-container {
            max-height: calc(100vh - 285px)!important;
        }
    }

    // @include media-breakpoint-between(md, llg) {
    //     padding-top: map-get($spacers, 3);
    // }
}

// $color_1: white;
// $background-color_1: #ccc;
// $background-color_2: white;
// $background-color_3: #4CAF50;

// .toggle-switch {
// 	position: relative;
// 	display: inline-block;
// 	width: 90px;
// 	height: 42px;
//     margin-top: 5px;
// }
// .toggle-switch-checkbox {
// 	opacity: 0;
// 	width: 0;
// 	height: 0;
// 	&:checked {
// 		+ {
// 			.toggle-switch-label {
// 				.toggle-switch-active {
// 					opacity: 1;
// 				}
// 				.toggle-switch-inactive {
// 					opacity: 0;
// 				}
// 				background-color: $background-color_3;
// 				.toggle-switch-inner {
// 					transform: translateX(56px);
// 				}
// 			}
// 		}
// 	}
// }
// .toggle-switch-label {
// 	position: absolute;
// 	cursor: pointer;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	background-color: $background-color_1;
// 	transition: 0.4s;
// 	border-radius: 34px;
// 	&:active {
// 		.toggle-switch-inner {
// 			width: 30px;
// 		}
// 	}
// }
// .toggle-switch-inner {
// 	position: absolute;
// 	width: 26px;
// 	height: 26px;
// 	left: 4px;
// 	bottom: 4px;
// 	background-color: $background-color_2;
// 	transition: 0.4s;
// 	border-radius: 50%;
// }
// .toggle-switch-active {
// 	position: absolute;
// 	color: $color_1;
// 	font-size: 12px;
// 	line-height: 34px;
// 	left: 5px;
// 	opacity: 0;
// }
// .toggle-switch-inactive {
// 	position: absolute;
// 	color: $color_1;
// 	font-size: 12px;
// 	line-height: 34px;
// 	right: 5px;
// }


// $color_1: white;
// $color_2: #000;
// $background-color_1: #404040;
// $background-color_2: #a6a6a6;

.pay-tabs {
    background-color: $black;
}
.pay-tabs label {
    margin-bottom: 0;
}

.tabs-checkbox {
	display: none;
}

.tab-label {
	flex: 1;
	padding: rem(10px) rem(20px);
    font-size: 0.875rem;
	text-align: center;
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s;
    color: $white;
    line-height: 3;
}

.tab-label.active {
	color: $black;
    font-family: $font-family-sans-serif;
    vertical-align: middle;
    background-color: $white;
    padding: rem(12px) rem(20px);
}

span.tab-label:hover {
    border-bottom: rem(2px) solid $gray-600;
}

span.tab-label.active:hover {
	border-bottom: 0;
}
