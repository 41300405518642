.navbar-expand-md {
    @include media-breakpoint-down(sm) {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .navbar-nav {
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
}
