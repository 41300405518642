//
// Base styles
//

.card {
    .list-group {
        margin-left: -($card-spacer-x);
        margin-right: -($card-spacer-x);
    }

    .refinements & {
        border: none;
        border-top: $card-border-width solid $card-border-color;
        border-radius: 0;

        &:last-child {
            border-bottom: $card-border-width solid $card-border-color;
        }

        .card {
            border: 0;
        }

        .card-header {
            padding: map-get($spacers, 6) 0 map-get($spacers, 1) 0;
            border: 0;
            background-color: $white;
            border-radius: 0;

            h2 {
                position: relative;

                &::after {
                    content: "";
                    width: rem(10px);
                    height: rem(10px);
                    margin: 0;
                    display: inline-block;
                    background: $svg-close no-repeat 0 0;
                    position: absolute;
                    right: rem(10px);
                    top: calc(50% - #{rem(4px)});
                }
            }

            .btn {
                padding: 0;
                height: auto;
                line-height: 1;
                margin: 0;

                &.title,
                &.btn-link {
                    color: $black;
                    font-size: $font-size-base;
                    text-decoration: none;
                    text-transform: uppercase;
                    position: relative;
                    display: block;
                    width: 100%;
                    text-align: left;
                    outline: none;
                    box-shadow: none;

                    &:focus,
                    &:active {
                        outline: none;
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    &::after {
                        content: "";
                        width: rem(10px);
                        height: rem(10px);
                        margin: 0;
                        display: inline-block;
                        background: $svg-arrow-up no-repeat 0 0;
                        position: absolute;
                        right: rem(10px);
                        top: calc(50% - #{rem(4px)});
                    }

                    &.collapsed {
                        &::after {
                            background: $svg-arrow-down no-repeat 0 0;
                        }
                    }
                }
            }

            &.collapsed {
                .btn {
                    &.title,
                    &.btn-link {
                        &::after {
                            background: $svg-arrow-down no-repeat 0 0;
                        }
                    }
                }

                h2 {
                    &::after {
                        content: "";
                        background: $svg-arrow-down no-repeat 0 0;
                    }
                }
            }
        }

        .card-body {
            padding: 0 0 24px;
            margin-left: $accordion-paragraph-margin;
            line-height: $line-height-xl;
        }
    }

    .accordion-cards & {
        border: none;
        border-top: $card-border-width solid $card-border-color;
        border-radius: 0;

        &:last-child {
            border-bottom: $card-border-width solid $card-border-color;
        }

        .card {
            border: 0;
        }

        .card-header {
            border: 0;
            border-radius: 0;
            background-color: $white;
            font-size: $font-size-xs;
            line-height: 1;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: map-get($spacers, 6) 0;

            &:hover {
                cursor: pointer;
            }
        }

        .card-body {
            padding: 0 0 ($spacer * 1.5);
            margin-left: $accordion-paragraph-margin;
            line-height: $line-height-xl;
        }
    }

    h4,
    h5 {
        &.card-header {
            font-weight: $font-weight-prebold;
        }
    }

    .card-header {
        &[role=button] {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        &.no-bg-header {
            background-color: $trans;
            margin: $card-spacer-y $card-spacer-x 0;
            padding: 0 0 $card-spacer-y;
        }
    }

    &--has-bg-color {
        background-color: $gray-400;

        .card-header,
        .card-footer {
            background: none;
        }

        .card-header:not(.card-header--no-divisor) {
            position: relative;

            &::after {
                position: absolute;
                right: map-get($spacers, 5);
                left: map-get($spacers, 5);
                content: "";
                bottom: 0;
                height: 1px;
                background: $card-border-color;
            }
        }
    }
}

.card-link {
    color: $primary;
    @include hover {
        text-decoration: none;
    }
}
