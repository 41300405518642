@import "utilities/functions";
@import "bootstrap/scss/functions";
// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts";

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/";

//--------------------------------
//  COLORS
//--------------------------------

// Theme colors documentation: https://getbootstrap.com/docs/4.1/getting-started/theming/#theme-colors

// Color Palette

/* Variable names from http://chir.ag/projects/name-that-color */

// Logo Color
$prussianblue:      #002956 !default;
$logocolor:         $prussianblue !default;

//Standard color
$white:             #fff;
$black:             #000;
$denim:             #1976d3;
$anakiwa:           #88c4ff;
$blumine:           #1d568e;
$orangepeel:        #ff9f00;
$creambrulee:       #ffdb9d;
$sapphire:          #303e9f;
$sirocco:           #757676;
$cinnabar:          #e64a19;
$pinegreen:         #00796a;
$japanese-laurel:   #046808;
$calypso:           #31708f;
$mustard:           #ffe353;
$tamarillo:         #92110e;
$california:        #fe9803;
$pomegranate:       #e85118;
$valencia:          #d73731;
$picton-blue:       #44a7f2;
$indigo:            #3b76ca;
$cornflower-blue:   #4c93eb;
$daisy-bush:        #502ea8;
$cerulean-blue:     #2553b5;
$cardinal:          #bf2121;
$greyinfo:          #262626;
$greylight:         #8d8d8d;
$dovegray:          #707070;
$silver:            #c9c9c9;
$trans:             transparent;
$stylegey:          #959499;
$gallery:           #efefef;
$cart-grey:         #c1c1c1;
$label-grey:        #222529;
$accessi-be-blue:   #0397d5;
$porpoise:          #dadada;

// Ui color
$monza:            #d0011b;

// Gray Colors - Bootstrap overrides
$gray-100:          #fbfbfb !default;
$gray-200:           mix($gray-100, #e6e6e6) !default;
$gray-300:          #e6e6e6 !default;
$gray-400:          #f6f6f6 !default;
$gray-500:          #ddd !default;
$gray-600:          #a6a6a6 !default;
$gray-700:          #666 !default;
$gray-800:          #404040 !default;
$gray-900:          $black !default;
$gray3:             #ccc !default;

// Accent / Misc. Caolors
$softstretch:      #66b6d1 !default;
$slipresistant:    #314657 !default;
$bubblenew:        #c3c3c3 !default;
$sustainable:      #44ae99 !default;
$corpsolutions:    #6dcff6 !default;

$grey1:             $gray-100 !default;
$grey2:             $gray-200 !default;
$grey3:             $gray-300 !default;
$grey4:             $gray-400 !default;
$grey5:             $gray-500 !default;
$grey6:             $gray-600 !default;
$grey7:             $gray-700 !default;
$grey8:             $gray-800 !default;

// Theme Mappings
$colors: () !default;
$colors: map-merge(
    (
        "denim": $denim,
        "anakiwa": $anakiwa,
        "blumine": $blumine,
        "orangepeel": $orangepeel,
        "creambrulee": $creambrulee,
        "sapphire": $sapphire,
        "cinnabar": $cinnabar,
        "pinegreen": $pinegreen,
        "japanese-laurel": $japanese-laurel,
        "calypso": $calypso,
        "mustard": $mustard,
        "tamarillo": $tamarillo,
        "california": $california,
        "pomegranate": $pomegranate,
        "valencia": $valencia,
        "picton-blue": $picton-blue,
        "indigo": $indigo,
        "cornflower-blue": $cornflower-blue,
        "daisy-bush": $daisy-bush,
        "cerulean-blue": $cerulean-blue,
        "trans": $trans,
        "white": $white,
        "black": $black
    ),
    $colors
);

// Main color variables
$brand-main:        $prussianblue !default;
$brand-second:      $monza !default;
$brand-light:       $white !default;
$brand-dark:        $black !default;
$primary:           $prussianblue !default;
$secondary:         $monza !default;
$dark:              $gray-800 !default;
$light:             $gray-400 !default;
$success:           $prussianblue !default;
$info:              $bubblenew !default;
$warning:           $monza !default;
$danger:            $monza !default;
$accent-1:          $softstretch !default;
$accent-2:          $slipresistant !default;
$accent-3:          $bubblenew !default;
$accent-4:          $sustainable !default;
$accent-5:          $corpsolutions !default;
$tertiary:          $gray-600 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        "brand-main": $brand-main,
        "brand-second": $brand-second,
        "brand-light": $brand-light,
        "brand-dark": $brand-dark,
        "primary": $primary,
        "secondary": $secondary,
        "black": $black,
        "dark": $dark,
        "light": $light,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "accent-1": $accent-1,
        "accent-2": $accent-2,
        "accent-3": $accent-3,
        "accent-4": $accent-4,
        "accent-5": $accent-5,
        "tertiary": $tertiary
    ),
    $theme-colors
);

//--------------------------------
//  OTHER THEME COLORS
//--------------------------------

$brand-success:         $sustainable !default;
$brand-danger:          $danger !default;
$brand-primary:         $monza !default;
$light-gray:            $gray-400 !default;
$slightly-darker-gray:  $gray-500 !default;
$dark-gray:             $gray-600 !default;
$darker-gray:           $gray-700 !default;
$darkest-gray:          $gray-800 !default;
$state-danger-text:     $white !default;

//--------------------------------
//  ALERTS
//--------------------------------

$alert-bg-level:        -10 !default; // bootstrap override
$alert-border-level:    -9 !default; // bootstrap override
$alert-color-level:     4 !default; // bootstrap override
$alert-danger-bg:       $brand-danger !default;
$alert-success-bg:      $brand-success !default;

//--------------------------------
//  BACKGROUNDS
//--------------------------------

// BG utility class documentation: https://getbootstrap.com/docs/4.0/utilities/colors/#background-color

// Gradients
// Gradients utility class documentation: https://getbootstrap.com/docs/4.0/utilities/colors/#background-gradient

$enable-gradients: false; // bootstrap override -> must be set to true for gradients to be visible on the site

//--------------------------------
//  BORDERS / DIVIDERS
//--------------------------------

// TODO - Should we use the border utility classes instead?

// Borders utility class documentation: https://getbootstrap.com/docs/4.0/utilities/borders/

$border-success:        rem(1px) solid $success !default;
$border-warning:        rem(1px) solid $warning !default;
$border-danger:         rem(1px) solid $danger !default;
$border-width:          1px !default; // could be bootstrap override
$border-nav:            rem(2px) solid $white !default;
$border-nav-active:     rem(2px) solid $black !default;

// Border radius
$border-radius:         rem(10px) !default;
$border-radius-xs:      rem(6px) !default;
$border-radius-sm:      rem(12px) !default;
$border-radius-md:      rem(20px) !default;
$border-radius-lg:      rem(6px) !default;
$border-radius-xlg:     rem(66px) !default;

// Borders
$border-color:          $slightly-darker-gray !default; // default color - could be bootstrap override
$border:                rem(1px) solid $slightly-darker-gray !default;
$border-thick:          rem(2px) solid $slightly-darker-gray !default;

$border2-color:         $black !default;
$border2:               rem(1px) solid $border2-color !default;

$border3-color:         $gray-600 !default;
$border3:               rem(1px) solid $border3-color !default;

$border4-color:         $gray-700 !default;
$border4:               rem(1px) solid $border4-color !default;

$horizontal-border-grey:    $slightly-darker-gray !default;
$hr-border-color:           $slightly-darker-gray !default;
$hr-border-width:           rem(1px) !default;
$horizontal-rule-grey:      $slightly-darker-gray !default;

//--------------------------------
//  BOX SHADOWS
//--------------------------------

$enable-shadows:    true; // bootstrap override
$shadow:            0 rem(3px) rem(6px) 0 rgba(0, 0, 0, 0.15) !default;
$shadow2:           0 rem(6px) rem(6px) 0 rgba(0, 0, 0, 0.25) !default;
$shadow3:           0 rem(6px) rem(6px) 0 rgba(0, 0, 0, 0.5) !default;

//--------------------------------
//  BODY
//--------------------------------
// Settings for the `<body>` element.
$body-bg:           $white !default;
$body-color:        $black !default;

//--------------------------------
//  SPACING
//--------------------------------

// TODO - Units of 8px are used per styleguide spacing section
// ($spacer * 0.25), // 4px
// ($spacer * 0.5), // 8px
// ($spacer * 0.75), // 12px
// $spacer, // 16px
// ($spacer * 1.25), // 20px
// ($spacer * 1.5), // 24px
// ($spacer * 2), // 32px
// ($spacer * 2.5), // 40px
// ($spacer * 5) // 80px

$spacer: 1rem; // 16px
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: ($spacer * 0.25),
        2: ($spacer * 0.5),
        3: ($spacer * 0.75),
        4: $spacer,
        5: ($spacer * 1.25),
        6: ($spacer * 1.5),
        7: ($spacer * 2),
        8: ($spacer * 2.5),
        9: ($spacer * 5)
    ),
    $spacers
);

//--------------------------------
//  TYPOGRAPHY
//--------------------------------
// stylelint-disable value-keyword-case
$font-family-sans-serif:    "soleil", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:     "soleil", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:          $font-family-sans-serif !default;
$font-lato-italic:          "Lato-Italic", $font-family-sans-serif !default;
$font-latolight-italic:     "Lato-LightItalic", $font-family-sans-serif !default;

$font-size-base:    1rem !default; // sets base font size applied to html element
$font-size-body:    $font-size-base !default; // font size applied to body element
$font-size-xxs:     $font-size-base * 0.75 !default;
$font-size-xs:      $font-size-base * 0.875 !default;
$font-size-xl:      $font-size-base * 1.125 !default;
$font-size-xxl:     $font-size-base * 1.25 !default;

// Fonts

// Headings

$h1-font-size:      $font-size-base * 2 !default; //bootstrap override - 32px
$h2-font-size:      $font-size-base * 2 !default; //bootstrap override - 32px
$h3-font-size:      $font-size-base * 1.5 !default; //bootstrap override - 24px
$h4-font-size:      $font-size-base * 1.5 !default; //bootstrap override - 24px
$h5-font-size:      $font-size-base * 1.5 !default; //bootstrap override - 24px
$h6-font-size:      $font-size-base * 1.375 !default; //bootstrap override - 22px
$h7-font-size:      $font-size-base * 1.125 !default; // 18px
$h8-font-size:      $font-size-base * 1.125 !default; // 18px
$h9-font-size:      $font-size-base !default; // 16px
$h10-font-size:     $font-size-base !default; // 16px

// Weights
$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-prebold:         600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          800 !default;

$checkout-font-weight:        $font-weight-normal !default;

$font-weight-base:            $font-weight-normal !default;

// line height
$line-height-base:            1.75 !default;
$line-height-xs:              1 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              1.333 !default;
$line-height-xl:              1.75 !default;
$line-height-xxl:             2 !default;

// Letter spacing
$letter-spacing-base:         0 !default;
$letter-spacing-minus:        -0.44px !default;
$letter-spacing-small:        0.28px !default;
$letter-spacing-xs:           0.36px !default;
$letter-spacing-sm:           0.48px !default;
$letter-spacing-md:           0.8px !default;
$letter-spacing-lg:           1.2px !default;
$letter-spacing-xl:           1.4px !default;

//--------------------------------
//  LINKS
//--------------------------------

$link-color:                    $gray-800 !default; //bootstrap override
$link-hover-color:              $gray-800 !default; //bootstrap override
$link-hover-visited:            $gray-700 !default;

$link-secondary-color:          $gray-700 !default;
$link-secondary-hover-color:    $gray-800 !default;
$link-secondary-hover-visited:  $gray-700 !default;

$link-nav-color:                $black !default;
$link-with-icon-color:          $black !default;

$link-decoration:               none !default;
$link-hover-decoration:         underline !default;
$link-decoration-alt:           underline !default;

//--------------------------------
//  BUTTONS
//--------------------------------

$btn-font-weight:               $font-weight-light !default;
$btn-padding-y:                 rem(19px) !default;
$btn-padding-x:                 rem(30px) !default;
$btn-line-height:               rem(20px) !default;
$btn-icon-size:                 rem(10px) !default;
$btn-icon-spacing:              rem(8px) !default;
$btn-link-color:                $primary !default; //bootstrap override
$btn-border-radius:             $border-radius-xs !default;
$btn-default-height:            rem(60px) !default;
$btn-secondary-height:          rem(50px) !default;
$btn-secondary-border-color:    $brand-dark !default;

$btn-main-color:                $white;
$btn-main-focus:                rem(2px) solid $gray-500 !default;
$btn-primary-height:            rem(60px) !default;
$btn-focus-box-shadow:          0 0 0 rem(2px) $gray-500;

// Btn disabled
$btn-disabled-opacity:          1 !default;
$btn-disabled-main-color:       $gray-600 !default;
$btn-disabled-main-bg:          $gray-400 !default;
$btn-disabled-main-border-color:$gray-400 !default;
$btn-disabled-alt-color:        $gray-600 !default;
$btn-disabled-alt-bg:           $gray-400 !default;
$btn-disabled-alt-border-color: $gray-400 !default;

// Btn primary
$btn-primary-color:             $btn-main-color !default;
$btn-primary-color-bg:          $black !default;
$btn-primary-hover-color:       $btn-main-color !default;
$btn-primary-hover-color-bg:    $greyinfo !default;

// Btn secondary
$btn-secondary-color:           $btn-main-color !default;
$btn-secondary-color-bg:        $monza !default;
$btn-secondary-hover-color:     $btn-main-color !default;
$btn-secondary-hover-color-bg:  $cardinal !default;

// Btn outline
$btn-outline-color:             $black !default;
$btn-outline-border-color:      $black !default;
$btn-outline-color-bg:          $white !default;

$btn-font-size:                 rem(15px) !default; //bootstrap override
$font-size-lg:                  $font-size-base !default; //bootstrap override

$btn-padding-y-lg:              rem(10px) !default; //bootstrap override

// Back to top
$btn-to-top-size-xs:            rem(40px) !default;
$btn-to-top-size-md:            rem(60px) !default;
$btn-to-top-bg:                 $black !default;
$btn-to-top-color:              $white !default;
$btn-to-top-radius:             50% !default;

// Tags
$btn-tags-color:                $black !default;
$btn-tags-bg:                   $gray-300 !default;
$btn-tags-border-radius:        $border-radius-xs !default;

// Badges
$btn-badges-size-mobile:        rem(44px) !default;
$btn-badges-size-desktop:       rem(53px) !default;
$btn-badges-color:              $white !default;
$btn-badges-radius:             50% !default;
$btn-badges-bg1:                $softstretch !default;
$btn-badges-size1:              rem(13px) !default;
$btn-badges-size1-xl:           rem(16px) !default;
$btn-badges-bg2:                $slipresistant !default;
$btn-badges-size2:              rem(10px) !default;
$btn-badges-size2-xl:           rem(14px) !default;
$btn-badges-bg3:                $bubblenew !default;
$btn-badges-size3:              rem(17px) !default;
$btn-badges-size3-xl:           rem(22px) !default;

//--------------------------------
//  ICONOGRAPHY
//--------------------------------

// TODO - Is this how we want to do SVGs (it's the bootstrap way)? Or some other approach...

$custom-select-indicator:           url("/assets/svg-icons/arrowDropdown.svg") !default;
$svg-arrow-right:                   url("/assets/svg-icons/arrowRight.svg") !default;
$svg-arrow-left:                    url("/assets/svg-icons/arrowLeft.svg") !default;
$svg-arrow-first:                   url("/assets/svg-icons/arrowFirst.svg") !default;
$svg-arrow-last:                    url("/assets/svg-icons/arrowLast.svg") !default;
$svg-arrow-right-white:             url("/assets/svg-icons/arrowRightWhite.svg") !default;
$svg-check:                         url("/assets/svg-icons/checkboxSelected.svg") !default;
$svg-arrow-down:                    url("/assets/svg-icons/arrowDown.svg") !default;
$svg-arrow-up:                      url("/assets/svg-icons/arrowUp.svg") !default;
$svg-close:                         url("/assets/svg-icons/close.svg") !default;
$svg-sustainable:                   url("/assets/svg-icons/sustainable.svg") !default;
$svg-facebook:                      url("/assets/svg-icons/socialFacebookLight.svg") !default;
$svg-instagramm:                    url("/assets/svg-icons/socialInstagramLight.svg") !default;
$svg-pinterest:                     url("/assets/svg-icons/socialPinterestLight.svg") !default;
$svg-footerPinterest:               url("/assets/svg-icons/footerPinterest.svg") !default;
$svg-tiktok:                        url("/assets/svg-icons/socialTiktokLight.svg") !default;
$svg-plus:                          url("/assets/svg-icons/plus.svg") !default;
$svg-minus:                         url("/assets/svg-icons/minus.svg") !default;
$svg-search:                        url("/assets/svg-icons/search.svg") !default;
$svg-accountDark:                   url("/assets/svg-icons/accountDark.svg") !default;
$svg-accountDarkV2:                 url("/assets/svg-icons/accountDarkV2.svg") !default;
$svg-shoppingBag:                   url("/assets/svg-icons/shoppingBag.svg") !default;
$svg-shoppingBagV2:                 url("/assets/svg-icons/shoppingBagV2.svg") !default;
$svg-arrowUpWhite:                  url("/assets/svg-icons/arrowUpWhite.svg") !default;
$svg-embroideryNeedle:              url("/assets/svg-icons/embroideryNeedle.svg") !default;
$svg-menuMobileDark:                url("/assets/svg-icons/menuMobileDark.svg") !default;
$svg-tooltip:                       url("/assets/svg-icons/tooltip.svg") !default;

//--------------------------------
//  FORMS
//--------------------------------
// all bootstrap overrides
$enable-validation-icons:       false !default;
$input-focus-width:             0 !default;
$form-default-height:           rem(50px) !default;
$form-group-margin-bottom:      map-get($spacers, 5) !default;
$input-border-color:            $gray-500 !default;
$input-border-color-active:     $black !default;
$input-color:                   $gray-800 !default;
$input-border-radius:           $border-radius-xs !default;
$input-box-shadow:              none !default;
$input-bg:                      $white !default;
$input-placeholder-color:       $gray-500 !default;
$input-padding-x:               1rem !default;
$input-padding-y:               0 !default;
$input-height:                  $form-default-height !default;

// Input placeholder
$input-placeholder-size:        $font-size-base;

// Input disabled
$input-disabled-bg:             $gray-400 !default;
$input-disabled-border:         $gray-500 !default;
$input-disabled-color:          $gray-500 !default;
$input-disabled-label:          $gray-500 !default;

// Input Error
$input-error-color:             $monza !default;
$input-error-size:              $font-size-base * 0.625 !default;
$input-error-space:             map-get($spacers, 1) !default;

// Labels
$label:                         $font-size-base * 0.875 !default;
$label-color:                   $black !default;
$custom-control-label-color:    $gray-800;
$input-error-space:             map-get($spacers, 2) !default;
$label-letter-space:            1.4px !default;
$label-weight:                  800 !default;
$label-line-height:             1.29;

//Form box Space
$input-box-space:               map-get($spacers, 5) !default;
$form-box-space:                map-get($spacers, 7) !default;

// Custom Select
$custom-select-bg:                          $white !default;
$custom-select-border-radius:               $border-radius-xs !default;
$custom-select-focus-box-shadow:            none !default;
$custom-select-padding-y:                   0 !default;
$custom-select-padding-x:                   rem(12px) !default;
$custom-select-bg-size:                     10px 5px !default;
$custom-checkbox-indicator-border-radius:   2px !default;
$custom-control-indicator-size:             rem(20px) !default;
$custom-control-indicator-box-shadow:       none !default;
$custom-control-indicator-bg:               $white !default;
$custom-control-indicator-active-bg:        $white !default;
$custom-control-indicator-active-border-color: $black !default;
$custom-control-indicator-checked-disabled-bg: $gray-500 !default;
$custom-control-indicator-checked-border-color:$gray-500 !default;
$custom-control-indicator-checked-color:       $gray-500 !default;
$custom-control-indicator-focus-border-color:  $gray-500 !default;
$custom-checkbox-indicator-icon-checked:    url("/assets/svg-icons/checkboxSelected.svg") !default;
$custom-radio-indicator-icon-checked:       url("/assets/svg-icons/radioSelected.svg") !default;

$text-muted:                                $gray-500 !default;

$custom-select-height:                      $form-default-height !default;
$custom-select-indicator:                   str-replace(url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99944 4L-3.49691e-07 6.99382e-07L8 4.76837e-07L3.99944 4Z' fill='%23242222'/%3E%3C/svg%3E%0A"), "#", "%23") !default;
$custom-select-background:                  $custom-select-indicator no-repeat right 19px center / $custom-select-bg-size !important !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
$custom-select-box-shadow:                  none !default;
$custom-select-border-radius:               $border-radius-xs !default;
$custom-select-focus-border-color:          $black !default;
$custom-select-font-weight:                 $font-weight-light !default;

//--------------------------------
//  BUTTONS & FORMS
//--------------------------------

$input-btn-padding-y:           rem(15px) !default; //bootstrap override
$input-btn-padding-x:           rem(15px) !default; //bootstrap override
$btn-box-shadow:                0 0 0 transparent !default; //bootstrap override
$btn-active-box-shadow:         0 0 0 transparent !default; //bootstrap override

//--------------------------------
//  DROPDOWN MENU
//--------------------------------

$menu-link:                     $gray-600 !default;
$close-menu-bg:                 $gray-400 !default;
$menu-bg:                       $gray-400 !default;
$menu-border-color:             $gray-500 !default;
$menu-color-dark:               $black !default;
$dropdown-link-hover-bg:        $gray-600 !default; //bootstrap override
$dropdown-link-hover-color:     $gray-600 !default; //bootstrap override

//--------------------------------
// BREADCRUMBS
//--------------------------------

$breadcrumb-bg:                 $white !default;
$breadcrumb-before-color:       $gray-800 !default;
$breadcrumb-active-color:       $gray-800 !default; //bootstrap override
$breadcrumb-divider-color:      $gray-800 !default; //bootstrap override
$breadcrumb-divider:            "/" !default;
$breadcrumb-margin-bottom:      0 !default;
$breadcrumb-padding-y:          10px !default;
$breadcrumb-padding-x:          0 !default;

//--------------------------------
// SWATCH Color
//--------------------------------
$swatch-size:                   rem(37px) !default;
$swatch-padding-size:           rem(2px) !default;
$swatch-border-size:            rem(2px) !default;
$swatch-border-active-color:    $black !default;

//--------------------------------
// CONTENT SWITCHER
//--------------------------------

$contentswitcher-active-color:  $gray-800 !default;
$contentswitcher-active-bg:     $white !default;
$contentswitcher-border:        $gray-500 !default;
$contentswitcher-hover-border:  $black !default;
$contentswitcher-active-border: $black !default;

$content-tab-color:             $black !default;
$content-tab-border:            rem(4px) solid $black !default;
$content-tab-spacer:            map-get($spacers, 1) !default;

//--------------------------------
// Product Tile
//--------------------------------

$product-tile-promo:            $monza !default;
$product-tile-name:             $black !default;
$product-tile-name-size:        $font-size-base * 1.125 !default;
$product-tile-price-color:      $black !default;
$product-tile-select-color:     $monza !default;

//This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
    (
        25: 25%,
        33: 33.3333%,
        50: 50%,
        66: 66.6666%,
        75: 75%,
        100: 100%
    ),
    $sizes
);

// LLG and XXL introduced for site specific cases. DON'T change for other things, add a new breakpoint if needed.
$grid-breakpoints: (
    xs:  0,
    sm:  576px,
    md:  992px,
    lg:  1025px,
    llg: 1200px,
    xl:  1400px,
    xxl: 1450px
);

$container-max-widths: (
    sm: 768px,
    md: 1025px,
    lg: 1280px,
    xl: 1448px
) !default;

$content-max-width: 1400px !default;

// To be used on elements allowed to extend further, like sticky header
$content-extra-width: 1600px !default;

// Grid columns

// Set the number of columns and specify the width of the gutters.

$grid-columns:                  12 !default;
$grid-gutter-width-xs:          rem(13px);
$grid-gutter-width:             rem(32px);
$container-padding-xs:          rem(16px);
$container-padding-md:          rem(20px);
$container-padding-lg:          rem(24px);

//--------------------------------
//  CARDS & ACCORDIONS
//--------------------------------

$accordion-spacer-y:            0 !default;
$accordion-spacer-x:            0 !default;
$accordion-btn-y:               0 !default;
$accordion-btn-x:               map-get($spacers, 6) !default;
$accordion-paragraph-margin:    0 !default;

//--------------------------------
//  MODALS
//--------------------------------

$modal-border-top:              0 !default;
$modal-border-radius:           $border-radius-sm !default;
$modal-content-border-radius:   $border-radius-sm !default;
$modal-header-border-width:     0 !default;
$modal-header-border-color:     transparent !default;
$modal-content-box-shadow-xs:   none !default;
$modal-content-box-shadow-sm-up:none !default;
$modal-header-padding:          rem(16px) rem(16px) 0 !default;
$modal-inner-padding:           rem(20px) rem(16px) rem(16px) !default;
$modal-header-padding-xl:       rem(40px) rem(40px) 0 !default;
$modal-inner-padding-xl:        rem(30px) rem(40px) rem(40px) !default;
$modal-footer-padding:          0 rem(16px) rem(16px) !default;
$modal-footer-padding-xl:       0 rem(40px) rem(40px) !default;
$modal-header-close-position:   rem(12px) !default;
$modal-header-size:             rem(15px) !default;
$modal-header-padding-y:        0 !default;
$modal-header-padding-x:        0 !default;
$modal-md:                      rem(863px) !default;

//--------------------------------
//  TABS
//--------------------------------

$nav-pills-link-color:              $gray-800 !default;
$nav-pills-link-bg-color:           $white !default;
$nav-pills-link-active-color:       $gray-800 !default;
$nav-pills-link-active-bg:          $white !default;
$nav-pills-link-border:             $gray-600 !default;
$nav-pills-link-active-border:      $black !default;
$nav-pills-link-border-radius:      $border-radius-xs !default;
$nav-pills-link-disabled-color:     $gray-600 !default;

$nav-tabs-link-color:               $black !default;
$nav-tabs-link-active-color:        $black !default;
$nav-tabs-link-hover-color:         $greylight !default;
$nav-tabs-link-hover-border-color:  $white !default;

$nav-link-disabled-color:           $greylight !default;
$nav-tabs-border-color:             $black !default;
$nav-tabs-border-bottom-width:      rem(4px) !default;

//--------------------------------
//  TABLES
//--------------------------------

$table-th-border-color:             $black !default;
$table-th-transform:                uppercase !default;
$table-td-border-color:             $gray-800 !default;
$table-td-color:                    $gray-800 !default;
$table-border-color:                $dovegray !default;
$table-bg-color:                    $gray-500 !default;

//--------------------------------
//  QUICKVIEW COMPONENT
//--------------------------------
$quickview-btn-width:               rem(172px) !default;
$quickview-btn-height:              $btn-secondary-height !default;
$quickview-btn-bg:                  $black !default;
$quickview-btn-color:               $white !default;
$quickview-btn-border-radius:       $border-radius-sm !default;
$quickview-font-size1:              $font-size-base * 0.625 !default;
$quickview-font-size2:              $font-size-base * 0.875 !default;
$quickview-font-size3:              $font-size-base !default;
$quickview-font-size4:              $font-size-base * 1.5 !default;
$quickview-font-size5:              $font-size-base * 2 !default;

//--------------------------------
//  TOOLTIPS
//--------------------------------

$tooltip-color:                     $gray-800 !default;
$tooltip-bg:                        $white !default;
$tooltip-arrow-color:               $white !default;
$tooltip-opacity:                   1 !default;
$tooltip-border-radius:             rem(10px) !default;
$tooltip-border:                    0 !default;
$tooltip-padding-y:                 rem(20px) !default;
$tooltip-padding-x:                 rem(20px) !default;
$tooltip-arrow-width:               rem(20px) !default;
$tooltip-arrow-height:              rem(20px) !default;
$tooltip-shadow:                    0 rem(3px) rem(9px) 0 rgba(0, 0, 0, 0.15) !default;
$tooltip-line-height:               $line-height-base;
$tooltip-text-align:                center;

//--------------------------------
//  MOTION
//--------------------------------

$ease-in-cubic-bezier:              cubic-bezier(0.55, 0.055, 0.675, 0.19) !default;
$ease-out-cubic-bezier:             cubic-bezier(0.215, 0.61, 0.355, 1) !default;
$card-hover-scale:                  scale(1.25) !default;
$button-hover-scaley:               scaleY(1.5) !default;

//--------------------------------
//  Card
//--------------------------------
$card-border-radius:                $border-radius-sm !default;
$card-border-color:                 $gray-500 !default;
$card-color:                        $gray-800 !default;

//--------------------------------
//  Pagination
//--------------------------------
$pagination-width:                  rem(24px) !default;
$pagination-height:                 rem(3px) !default;
$pagination-color:                  $gray-600 !default;
$pagination-color-active:           $brand-dark !default;

//--------------------------------
//  Footer
//--------------------------------
$footer-banner-bg-color:            $secondary !default;
$footer-banner-color:               $brand-light !default;
$footer-banner-border-color:        $brand-light !default;
$footer-button-bg:                  $brand-light !default;
$footer-button-color:               $gray-800 !default;
$footer-button-border-color:        $gray-500 !default;
$footer-signup-header:              $font-size-base * 1.25 !default;
$footer-signup-text:                $font-size-base * 0.875 !default;
$footer-copy-color:                 $gray-800 !default;
$footer-social-bg:                  $brand-dark !default;

@keyframes easeInCubicMotion {
    to {
        transform: translate(150px);
    }
}

@keyframes easeOutCubicMotion {
    to {
        transform: translate(150px);
    }
}

$ease-in-cubic:                     easeInCubicMotion 200ms $ease-in-cubic-bezier !default;
$ease-out-cubic:                    easeOutCubicMotion 200ms $ease-out-cubic-bezier !default;

//////////////////////////////////////////////////
////////  COMPONENTS (like PLP PDP Header Footer ect.)
/////////////////////////////////////////////////
//--------------------------------
// Embroidery
//--------------------------------

$embr-text-light-color:                     $gray-800 !default;
$embr-text-darker-color:                    $sirocco !default;

//--------------------------------
// PLP
//--------------------------------
$plp-product-name-link-color:               $brand-dark !default;
$plp-product-name-link-color-brand:         $gray-800 !default;
$plp-product-name-font-size:                $font-size-base !default;
$plp-product-name-font-weight:              $font-weight-prebold !default;
$plp-promo-color:                           $secondary !default;
$plp-price-bold-weight:                     $font-weight-prebold !default;
$plp-ref-count-result:                      $gray-800 !default;
$plp-ref-header-border:                     $gray-500 !default;
$plp-ref-bg-color:                          $white !default;
$plp-ref-headers-bg:                        $gray-300 !default;
$plp-ref-size-bg:                           $gray-400 !default;
$plp-ref-size-border-radius:                $border-radius-xs !default;
$plp-ref-white:                             $white !default;
$plp-ref-black:                             $black !default;
$plp-ref-size-disabled-color:               $gray-600 !default;

//--------------------------------
// PDP
//--------------------------------
$product-number-grey:                       $stylegey !default;
$pdp-promo-color:                           $secondary !default;
$pdp-divider-color:                         $slightly-darker-gray !default;
$pdp-breadcrumbs-color:                         $slightly-darker-gray !default;

//--------------------------------
// Header
//--------------------------------
$header-bottom-banner-border:           rem(1px) solid $brand-light !default;
$header-normal-color-link:              $primary !default;

//--------------------------------
// Search Suggestion
//--------------------------------
$suggestions-border-color:              $gray-300 !default;
$suggestions-shadow:                    $shadow !default;
$suggestions-border-radius:             $border-radius-xs !default;
$suggestions-hr-color:                  $silver !default;
$suggestions-header-color:              $brand-dark !default;
$suggestions-item-color:                $gray-800 !default;

//--------------------------------
// Mini Cart
//--------------------------------
$mini-cart-border-radius:               $border-radius-xs !default;
$mini-cart-divider-color:               $silver !default;
$mini-cart-approaching:                 $secondary !default;
$mini-cart-remove-btn-color:            $brand-dark !default;

//--------------------------------
// QuickView
//--------------------------------
$qv-style-color:                         $stylegey !default;
$qv-gopdp-color:                         $gray-800 !default;

//--------------------------------
// Custom scrollbar
//--------------------------------
$custom-scrollbar-color:                $gray-500 !default;
$custom-scrollbar-color-hover:          $gray-600 !default;

//--------------------------------
// Error page
//--------------------------------
$error-hero-background:                         $gallery !default;