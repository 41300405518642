@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            .title {
                line-height: 2.5rem; /* 40/16 */

                @include clearfix;

                &::after {
                    float: right;
                    content: "\f068";
                    font-family: "FontAwesome";
                }
            }

            .collapse,
            .collapsed {
                .title::after {
                    content: "\f067";
                }
            }

            .content,
            .card-body {
                display: none;
            }

            &.active {
                .title::after {
                    content: "\f068";
                    margin-top: -0.125em; /* 2/16 */
                }

                .collapsed {
                    .title::after {
                        content: "\f067";
                    }
                }

                .content,
                .card-body {
                    display: block;
                }
            }
        }
    }

    .container div.collapsible-#{$size} button.title {
        color: $black;
        text-decoration: none;
        border: none;
        background-color: transparent;

        &:hover {
            text-decoration: none;
        }
    }
}
