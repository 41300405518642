html {
    font-size: $font-size-base;
}

body {
    font-size: $font-size-body;
}

form {
    font-size: $font-size-body;
    margin-bottom: 0;
}

label {
    font-weight: $font-weight-bold;
}

ul:not(.list-group) li,
ol:not(.list-group) li {
    color: $darkest-gray;
    margin-bottom: 12px;
}
