@import "../variables";
@import "shapes";

@mixin swatch($diameter, $color) {
    @include circle($diameter, $color);

    border: 1px solid $white;
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    pointer-events: all;
    text-decoration: none;

    &.swatch-white {
        border-color: $gray-700;
    }

    &:hover:not(.unselectable),
    &.selected {
        &::after {
            position: absolute;
            content: "";
            top: -4px;
            right: -4px;
            bottom: -4px;
            left: -4px;
            border-radius: 50%;
            border: 2px solid $black;
        }
    }

    &:hover:not(.selected) {
        &::after {
            border-color: $gray-500;
        }
    }

    &.unselectable,
    &[disabled],
    &.disabled {
        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: -5px;
            bottom: 0;
            left: -5px;
            background-color: $gray-600;
            height: 2px;
            margin: auto;
            transform: rotate(45deg);
        }
    }

    &:focus,
    &:focus-visible {
        outline: none;
    }
}
