.icon {
    background-repeat: no-repeat;
    background-size: 100%;

    &.rotate0 {
        transform: rotate(0deg);
    }

    &.rotate45 {
        transform: rotate(45deg);
    }

    &.rotate90 {
        transform: rotate(90deg);
    }

    &.rotate135 {
        transform: rotate(135deg);
    }

    &.rotate180 {
        transform: rotate(180deg);
    }

    &.rotate-135 {
        transform: rotate(-135deg);
    }

    &.rotate-90 {
        transform: rotate(-90deg);
    }

    &.rotate-45 {
        transform: rotate(45deg);
    }

    &[class*="icon-arrowLast"],
    &[class*="icon-arrowFirst"] {
        aspect-ratio: 22/25;
        height: rem(9px);
    }

    &[class*="icon-arrowLast"] {
        background-image: $svg-arrow-last;
    }

    &[class*="icon-arrowFirst"] {
        background-image: $svg-arrow-first;
    }

    &[class*="icon-arrowRight"],
    &[class*="icon-arrowLeft"] {
        aspect-ratio: 5/9;
        height: rem(9px);
    }

    &[class*="icon-arrowRight"] {
        background-image: $svg-arrow-right;
    }

    &[class*="icon-arrowLeft"] {
        background-image: $svg-arrow-left;
    }

    &[class*="icon-arrowDown"],
    &[class*="icon-arrowUp"] {
        aspect-ratio: 5/3;
        height: rem(6px);
    }

    &[class*="icon-arrowDown"] {
        background-image: $svg-arrow-down;
    }

    &[class*="icon-arrowUp"] {
        background-image: $svg-arrow-up;
    }

    &[class*="icon-filter"] {
        width: rem(13px);
        height: rem(12px);
    }

    &[class*="icon-tooltip"] {
        background-image: $svg-tooltip;
        width: rem(18px);
        height: rem(18px);
    }

    &[class*="icon-close"] {
        background-image: $svg-close;
        width: rem(12px);
        height: rem(12px);
    }

    &[class*="accountDark"] {
        aspect-ratio: 19/25;
        background-image: $svg-accountDark;
        height: rem(16px);
    }

    &[class*="accountDarkV2"] {
        background-image: $svg-accountDarkV2;
        height: rem(20px);
    }

    &[class*="shoppingBag"] {
        aspect-ratio: 24/25;
        background-image: $svg-shoppingBag;
        height: rem(18px);
    }

    &[class*="shoppingBagV2"] {
        background-image: $svg-shoppingBagV2;
        height: rem(18px);
    }

    &[class*="menuMobileDark"] {
        aspect-ratio: 24/19;
        background-image: $svg-menuMobileDark;
        height: rem(16px);
    }

    &[class*="embroideryNeedle"] {
        background-image: $svg-embroideryNeedle;
        width: rem(23px);
        height: rem(23px);
    }
}
/*
.icon-order-info-phone {
    background: url("/assets/svg-icons/phone-white.svg") center center no-repeat;
}
*/
