.table {
    thead th {
        font-size: rem(14px);
        letter-spacing: 1.4px;
        text-transform: uppercase;
    }

    tbody {
        td {
            color: $table-td-color;
        }
    }

    &.table-bordered {
        thead th {
            border-bottom: 0;
        }
    }

    &.size {
        thead th {
            border-color: $table-border-color;

            &:first-child {
                background-color: $table-bg-color;
            }
            vertical-align: middle;
        }

        tbody {
            td {
                border-color: $table-border-color;
                white-space: nowrap;
                &:first-child {
                    text-transform: uppercase;
                    background-color: $table-bg-color;
                }
            }
        }
    }
}
