@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
// @import "productCard";

.minicart {
    position: relative;

    .popover_cover {
        &.fade{
            opacity: 1;
        }
    }

    .popover {
        top: 100% !important;
        left: auto !important;
        transform: none !important;
        right: 0;
        min-width: rem(460px);
        max-width: rem(460px);
        min-height: rem(300px);
        max-height: calc(100vh - 150px);
        overflow: hidden;
        display: none;
        z-index: 1040;

        @include media-breakpoint-down(sm) {
            min-width: rem(360px);
            max-height: calc(100vh - 100px);
        }

        &.show {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        &.bs-popover-auto[x-placement^=bottom] {
            margin-top: 0;
        }

        .popover-inner{
            width: 100%;
            height: 100%;

            .popover-body {
                padding: 0;

                .fa.fa-spinner{
                    left: 45% !important;
                }
    
                .container {
                    border-radius: 0.375rem;
                    padding: 1.25rem;
    
                    &.cart {
                        background-color: $white;
                    }
    
                    .minicart-content {
                        -webkit-box-flex: 0;
                        -ms-flex: 0 1 auto;
                        flex: 0 1 auto;
                        margin-right: rem(-10px);
    
                        .minicat-header {
                            border-bottom: rem(1px) solid $silver;
                            margin-right: rem(10px);
                            margin-bottom: rem(15px);
                            padding: rem(2px) 0 rem(20px);
                            line-height: 1;
    
                            h3 {
                                font-size: 1.125rem;
                                font-weight: 700;
    
                                .minicart-quantity {
                                    line-height: 1.125rem;
                                    font-size: .875rem;
                                }
                            }
                        }
    
                        .product-summary {
                            min-height: 5.5rem;
                            max-height: calc(100vh - 435px);
                            overflow-y: auto;
                            overflow-x: hidden;
                            padding-right: rem(10px);
    
                            .product-summary-item {
                                border: 0;
                                border-radius: 0;
                                padding-bottom: rem(10px);
    
                                &:not(:first-child) {
                                    border-top: 0.0625rem solid $silver;
                                    padding-top: 1.25rem;
                                }
                            }
    
                            .product-line-item-details {
                                overflow-y: hidden;
    
                                .item-image {
                                    background-color: $gray-400;
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;
                                    -webkit-box-align: center;
                                    -ms-flex-align: center;
                                    align-items: center;
                                    width: 100%;
                                    height: auto;
                                    margin: 0;
    
                                    .product-image {
                                        max-height: none;
                                        max-width: none;
                                        width: 100%;
                                    }
                                }
    
                                .item-attributes {
                                    vertical-align: top;
                                    padding-left: 0;
    
                                    .line-item-header {
                                        display: -webkit-box;
                                        display: -ms-flexbox;
                                        display: flex;
                                        -webkit-box-orient: horizontal;
                                        -webkit-box-direction: normal;
                                        -ms-flex-direction: row;
                                        flex-direction: row;
                                        -webkit-box-pack: justify;
                                        -ms-flex-pack: justify;
                                        justify-content: space-between;
                                    }
    
                                    .line-item-name {
                                        color: $black;
                                        font-size: .875rem;
                                        font-weight: 700;
                                        line-height: 1.21;
                                        margin-bottom: 3px;
                                        padding-right: rem(15px);
                                        text-overflow: unset;
                                        white-space: normal;
                                    }
    
                                    .remove-line-item {
                                        position: relative;
    
                                        .remove-product {
                                            color: $black;
                                            font-size: .75rem;
                                            height: auto;
                                            width: auto;
                                            outline: none;
                                            -webkit-box-shadow: none;
                                            box-shadow: none;
                                            text-decoration: underline;
                                            text-transform: capitalize;
    
                                            &.btn {
                                                float: right;
                                                background-color: $white;
                                                border: none;
                                                margin-top: -0.313em;
                                                padding: 0;
                                            }
                                        }
    
                                    }
    
                                    .line-item-style {
                                        font-size: .75rem;
                                        margin-bottom: rem(10px);
                                    }
    
                                    .line-item-attributes {
                                        color: $black;
                                        font-size: .75rem;
                                        margin: 0;
    
                                        span {
                                            text-transform: uppercase;
                                            font-weight: 700;
                                            letter-spacing: normal;
                                            color: $black;
                                            padding-right: rem(3px);
                                        }
                                    }
    
                                    .input-counter {
                                        max-width: 130px;
                                        text-align: center;
                                        display: inline-block;
                                        position: relative;
    
                                        span {
                                            position: absolute;
                                            top: 0;
                                            background-color: transparent;
                                            cursor: pointer;
                                            color: $black;
                                            width: rem(37px);
                                            height: 100%;
                                            line-height: rem(43px);
                                            transition: 0.5s;
                                            font-size: 0.875rem;
    
                                            &.minus-btn {
                                                left: 0;
                                                &.disabled {
                                                    background-color: $gray-400;
                                                    border-top-left-radius: 0.375rem;
                                                    border-bottom-left-radius: 0.375rem;
                                                    border-top: 0.0625rem solid $gray-500;
                                                    border-bottom: 0.0625rem solid $gray-500;
                                                    border-left: 0.0625rem solid $gray-500;
                                                    cursor: default;
                                                  }
                                            }
    
                                            &.plus-btn {
                                                right: 0;
                                            }
    
                                            &:hover {
                                                color: #222;
                                            }
    
                                        }
    
                                        input {
                                            height: rem(40px);
                                            color: $black;
                                            outline: 0;
                                            display: block;
                                            border: none;
                                            text-align: center;
                                            width: 100%;
                                            font-size: 1rem;
                                            font-weight: 700;
                                            border-radius: 0.375rem;
                                            border: 0.0625rem solid $gray-500;
    
                                            &::-webkit-input-placeholder {
                                                color: #222;
                                            }
    
                                            &:-ms-input-placeholder {
                                                color: #222;
                                            }
    
                                            &::-ms-input-placeholder {
                                                color: #222;
                                            }
    
                                            &::placeholder {
                                                color: #222;
                                            }
    
                                        }
                                    }
    
                                    .line-item-total-price {
                                        .price {
                                            color: $black;
                                            font-size: 1rem;
                                            font-weight: 700;
                                            padding: rem(6px) 0;
                                        }
                                    }
                                }
                            }
    
                            .embroidery-lineitem-select {
                                .main-label {
                                    font-size: .875rem;
                                    font-weight: 800;
                                    text-transform: uppercase;
                                    color: $black;
                                }
                            }
                        }
                    }
    
                    .minicart-footer {
                        border-top: 0.0625rem solid $silver;
                        font-size: 1rem;
    
                        .estimated-total {
                            margin-top: 0.625em;
                        }
    
                        .sub-total,
                        .sub-total-label {
                            font-size: 1em;
                            font-weight: 700;
                        }
    
                        .checkout-continue {
                            position: fixed;
                            bottom: 0;
                            left: 0;
                            z-index: 2;
                            padding-right: rem(15px);
                            padding-left: rem(15px);
    
                            @include media-breakpoint-up(md) {
                                position: static;
                                padding-right: 0.938em;
                                padding-left: 0.938em;
                            }
                        }
                    }
                }
            }
        }
    }

}