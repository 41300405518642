.section-title {
    h1 {
        @include media-breakpoint-down(sm) {
            @include font-size($h6-font-size);
        }

        &:not(.h2) {
            font-weight: $font-weight-normal;
            letter-spacing: -0.44px;
        }
    }
}

.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
}

.loading-wrapper {
    .position-absolute {
        background-color: rgba(255, 255, 255, 0.9);
        left: 0;
        top: 0;
        z-index: 10;
    }
}

input[placeholder] {
    text-overflow: ellipsis;
}

.container a:not(.btn-primary, .btn-outline-primary, .quickview, .btn-link, .nav-link) {
    color: $link-color;
}

.error-messaging {
    margin: auto;
    max-width: 1400px;
}

.address-error-messaging {
    .alert {
        margin: rem(12px) rem(20px) 0;
    }
}

.error-hero-image {
    max-width: 100%;
    width: 100%;

    @include media-breakpoint-up(md) {
        width: auto;
    }
}

.error-page-info {
    @include media-breakpoint-up(md) {
        background-color: $error-hero-background;
    }
}

.error-page-title {
    line-height: 1.45;
    text-transform: capitalize;

    @include media-breakpoint-up(md) {
        font-size: rem(40px);
    }
}

.error-page-subtitle {
    line-height: 28px;
    margin: 6px 0 30px;
    color: $dark;

    @include media-breakpoint-up(md) {
        font-size: rem(24px);
        line-height: 40px;
        margin-top: 13px;
        color: $black;
    }
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    @include media-breakpoint-up(md) {
        max-width: 350px;
    }
}

.error-page-search .site-search {
    width: auto;
}

.error-page-category {
    width: 50%;

    @include media-breakpoint-up(md) {
        width: 20%;
    }
}

.error-top-brands {
    @include media-breakpoint-up(md) {
        margin: 0 rem(120px);
    }

    div {
        width: 50%;

        @include media-breakpoint-up(md) {
            width: 16.66%;
        }
    }

    img {
        width: 100%;
    }
}

.error-bottom {
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        margin: 0 rem(120px);
    }

    .single-tile {
        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    .four-tiles {
        @include media-breakpoint-up(md) {
            width: 50%;
        }

        display: flex;
        flex-wrap: wrap;

        > div {
            width: 50%;
        }
    }

    img {
        width: 100%;
    }
}

.skip {
    position: absolute;
    left: 0;
    top: -4.2em;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
    transition: all 0.2s ease-in-out;
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.link-underline {
    text-decoration: underline;
}

.link-nodecoration {
    text-decoration: none !important;
}

.link-underline,
.link-nodecoration {
    &:hover,
    &:focus,
    &:active {
        text-decoration: none !important;
    }

    &:active:focus,
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }
}

.link-secondary {
    color: $link-secondary-color;
    font-size: $font-size-xs;
    letter-spacing: 0.28px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $link-secondary-hover-color;
        text-decoration: underline;
    }
}

/* Custom Scrollbar. Chrome / Safari and Edge full support.
Firefox doesn't allow pixel width neither border radius, but its close enough */
.has-custom-scrollbar {
    scrollbar-color: $custom-scrollbar-color transparent;// stylelint-disable-line
    scrollbar-width: thin;// stylelint-disable-line

    &::-webkit-scrollbar {
        border: 3px solid transparent;
        border-radius: 10px;
        background: transparent;
        width: 16px;
        height: 16px;
        box-shadow: inset 0 0 1px 1px #a6a6a6;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 5px solid transparent;
        box-shadow: inset 0 0 4px 4px #a6a6a6;

        &:hover {
            box-shadow: inset 0 0 4px 4px #8d8d8d;
        }
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.add-to-saveforlater-messages,
.remove-from-saveforlater-messages {
    z-index: 200;
    background-color: $white;
}

.cards {
    margin: 0 -6px;

    .creditcard {
        margin: 0 6px;
        width: 47px;
        height: 30px;
    }

    .mastercard {
        background-image: url("/assets/images/MasterCard.svg");
    }

    .visa {
        background-image: url("/assets/images/Visa.svg");
    }

    .amex {
        background-image: url("/assets/images/AmericanExpress.svg");
    }

    .discover {
        background-image: url("/assets/images/Discover.svg");
    }
}

.flex-equal {
    flex: 1;
}

.pricing {
    &.adjusted-price {
        color: $monza;
        font-weight: $font-weight-prebold;
    }
}

.us-autocomplete-pro-menu {
    background-color: $gray-400;
    font-size: rem(14px);
    border: solid 1px $cart-grey;
    border-radius: rem(6px);
    color: $gray-800;
    cursor: pointer;
}

.clear {
    clear: both !important;
}

.divider {
    background-color: $cart-grey;
    display: inline-block;
    height: $font-size-xl;
    margin: 0 map-get($spacers, 2);
    width: 1px;
    vertical-align: text-top;
}

button,
*[role=button] {
    span {
        &.icon {
            display: block;
        }
    }
}

//Chat CSS fixes/overwrites
.conversation-entry-bot__message-body {
    p,
    span,
    ul,
    li,
    div {
        color: $white !important;
    }
}

// GlobalE
#globale_popup,
#globale_csc_popup {
    margin: auto !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);

    @include media-breakpoint-down(sm) {
        max-width: 100%;

        .glDefaultPopupContainer {
            max-width: 90%;
            margin: 0 auto;
        }
    }
}
