.nav-link {
    margin-right: $spacer;
}

//
// Tabs
//

.nav.nav-tabs {
    border: none;

    .nav-link {
        padding: $nav-link-padding-y 0;
        color: $nav-tabs-link-color;
        border: 0;
        font-weight: $font-weight-light;

        &:hover {
            color: $nav-tabs-link-hover-color;
        }

        &.disabled,
        &:disabled {
            color: $nav-tabs-link-hover-color;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $nav-tabs-link-active-color;
        border: 0;
        border-bottom: $nav-tabs-border-bottom-width solid $nav-tabs-border-color;
        font-weight: $font-weight-normal;
    }
}

//
// Pills
//

.nav.nav-pills {
    .nav-link {
        border: 1px solid $nav-pills-link-border;
        border-radius: $btn-border-radius;

        &:hover {
            border-color: $nav-pills-link-active-color;
        }

        &.disabled {
            color: $nav-link-disabled-color;
            background-color: $trans;
            position: relative;

            &::before,
            &::after {
                content: "";
                border-bottom: rem(1px) solid $nav-pills-link-border;
                transform: rotate(15deg);
                width: 100%;
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
            }

            &::before {
                transform: rotate(-15deg);
            }
        }

        &.active {
            box-shadow: inset 0 0 0 2px $black;
        }
    }
}

.main-nav {
    font-size: $font-size-base * 1.125;
    border-bottom: $border-nav;

    &:hover {
        border-bottom: $border-nav-active;
    }
}
