
// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

h1, .h1 {
    @include font-size($h1-font-size);

    font-weight: $font-weight-normal;
    letter-spacing: -0.8px;
}

h2, .h2 {
    @include font-size($h2-font-size);

    font-weight: $font-weight-prebold;
    letter-spacing: 0.48px;
}

h3, .h3 {
    @include font-size($h3-font-size);

    font-weight: $font-weight-normal;
    letter-spacing: 0.36px;
}

h4, .h4 {
    @include font-size($h4-font-size);

    font-weight: $font-weight-bold;
    letter-spacing: 1.2px;
}

h5, .h5 {
    @include font-size($h5-font-size);

    font-weight: $font-weight-light;
    letter-spacing: normal;
}

h6, .h6 {
    @include font-size($h6-font-size);

    font-weight: $font-weight-normal;
    letter-spacing: -0.44px;
}

hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
}

p {
    color: $dark;
}

.label,
.non-input-label {
    color: $black;
    font-size: $label;
    font-weight: $label-weight;
    letter-spacing: $label-letter-space;
    line-height: $label-line-height;
    text-transform: uppercase;
}

.non-input-label {
    color: $gray-800;
}

.text-small {
    font-size: rem(14px);
    line-height: 1.43;
}

.text-smaller {
    font-size: rem(13px);
    line-height: 1;
    letter-spacing: 0.33px;
}

.caption {
    font-size: rem(10px);
    font-weight: $font-weight-light;
    letter-spacing: 0.5px;
    line-height: 1.3;
}

.caption-light {
    color: $gray-600;
    font-size: rem(10px);
    line-height: 1;
}

.font-weight-prebold {
    font-weight: $font-weight-prebold !important;
}
