.my-account-page {
    .account-page-title {
        font-size: 1.375rem;
        font-weight: 600;
    
        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    .card-header {
        h2, h3 {
            font-size: 1.5rem;
            margin-bottom: 0;

            &.card-title {
                font-weight: 600;
                line-height: 2.125rem;
            }
        } 
        a {
            text-decoration: underline;

            &.card-header-link {
                border-left: rem(1px) solid $gray-500;
                
            }
        }
    }
    .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
        color: #404040;

        &.profile-card-body {
            dl {
                -webkit-column-break-inside: avoid;
                -moz-column-break-inside: avoid;
                break-inside: avoid;
            }
        }
    }
    .link-underline {
        &:hover {
            text-decoration: underline !important;
        }
    }
    .link-no-underline {
        &:hover {
            text-decoration: none !important;
        }
    }
    .account-sections-card {
        @include media-breakpoint-up(md) {
            padding: 30px 10px 10px;
        }
    }
    .responsive-border {
        border-right: rem(1px) solid $gray-500;
        @include media-breakpoint-down(sm) {
            border-bottom: rem(1px) solid $gray-500;
            border-right: none;
        }
    }
    .account-title-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: left;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @include media-breakpoint-up(md) {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
        .account-page-title {
            font-size: 1.375rem;
            font-weight: 600;
            @include media-breakpoint-up(md) {
                font-size: 2rem;
            }
        }
        .account-back-link {
            font-size: .875rem;
            @include media-breakpoint-up(md) {
                position: absolute;
                right: 0;
                bottom: 0.5rem;
                font-size: 1rem;
            }
        }
    }
    .form-group {
        label:not(.custom-control-label) {
            color: $black;
            font-size: .875rem;
            font-weight: 800;
            letter-spacing: rem(1.4px);
            line-height: 1.29;
            text-transform: uppercase;
        }
        &.required {
            .form-control-label:after {
                content: "*";
                color: $black;
            }
        }
        .show-password {
            position: absolute;
            top: calc(50% - 14px);
            right: 0.9375rem;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .form-control.is-invalid, .was-validated .form-control:invalid {
            border-color: #d0011b;
            background-image: none;
        }
    }
        .order-history-divisor {
            border-bottom: 1px solid $gray-500;
    
            @include media-breakpoint-up(md) {
                border-bottom: none;
                border-right: 1px solid $gray-500;
            }
            .label {
                color: $black;
                font-size: .875rem;
                font-weight: 800;
                letter-spacing: 1.4px;
                line-height: 1.29;
                text-transform: uppercase;
            }
            .order-history-labels {
                
                @include media-breakpoint-up(md) {
                    max-width: rem(150px);
                }
            }

        }
}

.edit-content-page {
    @include media-breakpoint-up(md) {
        .container-fluid {
            max-width: rem(868px);
        }
    }
    .dropdown-item {
        &:hover {
            color: $white;
        }
    }
}

.my-account-link {
    @include media-breakpoint-up(md) {
        position: absolute;
        right: 0;
    }
}

.btn-show-password {
    right: 0.975rem;
    top: 2.65rem;
}
