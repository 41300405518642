.form-check,
.form-group {
    label:not(.custom-control-label) {
        color: $black;
        font-size: $label;
        font-weight: $label-weight;
        letter-spacing: $label-letter-space;
        line-height: $label-line-height;
        text-transform: uppercase;
    }

    label.custom-control-label {
        color: $custom-control-label-color;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        line-height: $custom-control-indicator-size;
        position: relative;
        vertical-align: top;

        @include media-breakpoint-up(lg) {
            font-size: $label;
        }

        &::before,
        &::after {
            top: 0;
        }
    }
}

.form-control {
    font-size: $font-size-body;
    border-radius: $input-border-radius;

    &:active,
    &.active,
    &.focus,
    &:focus {
        border-color: $input-border-color-active;

        &:disabled {
            border-color: $input-border-color;
        }
    }

    &::placeholder {
        font-size: $input-placeholder-size;
    }
}

.custom-control-input {
    height: $custom-control-indicator-size;

    ~ .custom-control-label {
        cursor: pointer;
    }

    ~ .custom-control-label::before {
        border: $border;
    }

    &:checked ~ .custom-control-label {
        &::before {
            color: $custom-control-indicator-checked-color;
            background-color: $custom-control-indicator-bg;
        }

        &::after {
            background-size: 100%;
        }
    }

    &:not(:disabled):hover ~ .custom-control-label::before {
        border-color: $input-border-color-active;
    }

    &:active ~ .custom-control-label::before {
        color: $custom-control-indicator-active-color;
        background-color: $custom-control-indicator-active-bg;
    }
}

.custom-control-label:hover {
    &::before {
        border-color: $input-border-color-active;
    }
}

.custom-radio {
    .custom-control-input {
        &:checked {
            ~ .custom-control-label::before {
                background-color: $custom-control-indicator-bg;
            }

            &:hover ~ .custom-control-label::before {
                border-color: $custom-control-indicator-checked-color;
            }
        }
    }
}

.custom-radio,
.custom-checkbox {
    .custom-control-input:disabled ~ .custom-control-label::after {
        background-color: $custom-control-indicator-checked-disabled-bg;
        background-size: 100%;
        background-position: center;
        width: rem(10px);
        height: rem(3px);
        position: absolute;
        top: 45%;
        margin-left: rem(5px);
    }
}

.invalid-feedback,
.form-text {
    font-size: $input-error-size;
}

.custom-control {
    line-height: 1;
    min-height: 20px;
}

// remove iE11 big X button
input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: $svg-check;
}

.form-check {
    label.form-check-label {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: -1.75rem;
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            pointer-events: none;
            content: '';
            background-color: $white;
            border: 1px solid $gray-500;
            box-shadow: none;
        }

        &::after {
            position: absolute;
            top: 0;
            left: -1.75rem;
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            content: '';
        }
    }

    input[type=radio] {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1.25rem;
        height: 1.25rem;
        opacity: 0;

        ~ .form-check-label::before {
            border-radius: 50%;
        }

        &:checked {
            ~ .form-check-label {
                color: $black;
            }

            ~ .form-check-label::before {
                background-color: $custom-control-indicator-bg;
            }

            ~ .form-check-label::after {
                background-image: escape-svg($custom-radio-indicator-icon-checked);
            }

            ~ .form-check-label:hover {
                &::before {
                    border-color: $gray-500;
                }
            }
        }

        &:hover ~ .form-check-label::before {
            border-color: $black;
        }
    }

    input[type=radio],
    input[type=checkbox] {
        margin: 0;
    }

    label.form-check-label {
        &:hover {
            color: $black;

            &::before {
                border-color: $black;
            }
        }
    }
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}