.breadcrumb {
    background-color: $breadcrumb-bg;
    border-radius: 0;
    padding: 0 0 10px;

    @include media-breakpoint-up(md) {
        padding: 15px 0;
    }
}

.breadcrumb-item {
    font-size: $font-size-xxs;
    font-weight: $font-weight-normal;
    margin-bottom: 0 !important;

    @include media-breakpoint-up(md) {
        font-size: rem(12px);
    }

    @include media-breakpoint-down(sm) {
        &:first-of-type,
        &:nth-child(2)::before {
            display: none;
        }

        + .breadcrumb-item:nth-child(2) {
            padding-left: 0;
        }
    }

    a {
        color: $breadcrumb-before-color;
    }

    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item::before {
        color: $breadcrumb-before-color;
    }

    &.active {
        color: $breadcrumb-active-color;
    }

    .sep {
        padding: 0 $breadcrumb-item-padding;
    }
}
