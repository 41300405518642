.badge {
    width: $btn-badges-size-mobile;
    height: $btn-badges-size-mobile;
    color: $btn-badges-color;
    border-radius: $btn-badges-radius;
    display: flex;
    align-items: center;
    text-align: center;
    background: $black;
    white-space: normal;
    font-style: italic;
    font-weight: $font-weight-normal;
    padding: 0;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        width: $btn-badges-size-desktop;
        height: $btn-badges-size-desktop;
        letter-spacing: rem(-1px);
    }

    &.badge1 {
        background: $btn-badges-bg1;
        font-size: $btn-badges-size1;

        @include media-breakpoint-up(lg) {
            font-size: $btn-badges-size1-xl;
        }

        + .badge3,
        + .badge2 {
            top: rem(70px);
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        + .badge2 {
            + .badge3 {
                display: none;
            }
        }
    }

    &.badge2 {
        background: $btn-badges-bg2;
        font-size: $btn-badges-size2;

        @include media-breakpoint-up(lg) {
            font-size: $btn-badges-size2-xl;
        }

        + .badge3 {
            top: rem(70px);
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &.badge3 {
        background: $btn-badges-bg3;
        font-size: $btn-badges-size3;

        @include media-breakpoint-up(lg) {
            font-size: $btn-badges-size3-xl;
        }
    }

    .image-container & {
        position: absolute;
        left: rem(10px);
        top: rem(10px);
    }
}
