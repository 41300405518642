.faq-area {
    #accordion {
        .card {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $gray-500;
            .card-header {
                padding: 0;
                border: none;
                background-color: transparent;
                border-radius: 0;
                a {
                    padding: 0.75rem 1.75rem 0.75rem 0;
                    width: 100%;
                    display: block;
                    text-decoration: none;
                    background-image: $svg-arrow-down;
                    background-repeat: no-repeat;
                    background-size: 0.625rem 0.375rem;
                    background-position: right rem(10px) center;

                    &:not(.collapsed) {
                        color: #66b6d1;
                        background-image: $svg-arrow-up;
                    }
                }
            }
            .card-body {
                padding: 0 0 0.75rem;
            }
            &:first-child {
                border-top: 1px solid $gray-500;
            }
        }
    } 
}