.sidebar-navigation {
    margin-bottom: rem(24px);

    @include media-breakpoint-up(md) {
        margin-top: rem(56px);
    }

    @include media-breakpoint-down(sm) {
        border-bottom: rem(1px) solid $grey5;
        border-top: rem(1px) solid $grey5;
        width: calc(100% + ($container-padding-xs * 2));
        margin-left: rem(-$container-padding-xs);

        #sidebar-content {
            display: none;
            padding-left: rem($container-padding-xs * 2);
            padding-right: rem($container-padding-xs * 2);
            padding-bottom: rem(20px);
        }

        &.active #sidebar-content {
            display: block;
        }
    }

    .sidebar-navigation-toggle {
        font-weight: $font-weight-bold;
        display: block;
        width: 100%;
        text-align: left;
        border: 0;
        padding: rem(20px) $container-padding-xs;
        color: $grey8;
        background-color: $trans;
        background-image: url("/assets/svg-icons/arrowDown.svg");
        background-repeat: no-repeat;
        background-position: right $container-padding-xs center;
    }

    &.active .sidebar-navigation-toggle {
        background-image: url("/assets/svg-icons/arrowUp.svg");
    }

    h3 {
        font-size: rem(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        color: $black;
    }

    .sidebar-navigation-block:not(:last-child) {
        padding-bottom: rem(24px);

        &:not(:last-child) {
            margin-bottom: rem(24px);
            border-bottom: rem(1px) solid $grey5;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 0;
        line-height: 1.7;

        a {
            color: $grey7;
            font-size: rem(14px);
            letter-spacing: rem(0.28px);

            &.active {
                font-weight: $font-weight-bold;
                color: $grey8;
            }
        }
    }
}
