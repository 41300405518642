.checkout-page {
    .number-circle {
        background-color: $black;
        border: 1px solid $black;
        border-radius: 50%;
        color: $white;
        display: flex;
        font-size: $h3-font-size;
        font-weight: $font-weight-normal;
        justify-content: center;
        line-height: 33px;
        margin-right: rem(9px);
        height: rem(38px);
        width: rem(38px);

        &.white {
            background-color: $white;
            color: $black;
        }
    }

    .edit-button {
        font-size: $font-size-xs;
    }

    .card {
        border-radius: $border-radius-xs;

        .card-header,
        .card-header:first-child {
            border-radius: subtract($border-radius-xs, $card-border-width) subtract($border-radius-xs, $card-border-width) 0 0;
        }

        .card-body {
            .customer-summary {
                p {
                    margin: 0;
                }
            }

            >.customer-summary:not(:last-of-type) {
                margin-bottom: 1rem;
            }

            .shipping-methods {
                display: grid;
                flex-wrap: wrap;
                gap: $spacer ($spacer * 2);
                grid-template-columns: 1fr;
                margin: 0;

                li {
                    margin: 0;
                }

                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        h6 {
            color: $black;
            font-weight: $font-weight-prebold;
            letter-spacing: 0;
            line-height: inherit;
            margin-top: $card-spacer-x * 2;
        }

        &.card-sidebar {
            border: none;

            .card-header {
                border-color: $cart-grey;
                background-color: $trans;
                margin: 0 rem(20px);
                padding: rem(18px) 0 rem(15px);
            }

            .card-body {
                padding: rem(15px) rem(20px) rem(18px);
            }

            .list-group {
                margin: 0;
            }

            .list-group-item {
                background-color: $trans;
                border-color: $cart-grey;
                padding-left: 0;
                padding-right: 0;
            }

            &.order-summary {
                .list-group-item {
                    display: flex;
                    justify-content: space-between;

                    &:not(.order-summary-total) {
                        border: none;
                        line-height: $line-height-sm;
                        padding-bottom: rem(10px);
                        padding-top: 0;
                    }

                    &.order-summary-total,
                    &.order-summary-savings {
                        font-size: $font-size-xl;
                        font-weight: $font-weight-bold;
                    }

                    &.order-summary-total {
                        color: $black;
                        border-width: 1px 0;
                        margin: rem(15px) 0;
                    }

                    &.order-summary-savings {
                        text-align: center;
                        padding-bottom: 0;

                        span {
                            width: 100%;
                        }
                    }
                }
            }

            &.products-summary {
                .card-header {
                    border: none;
                    padding: rem(18px) 0;

                    .icon {
                        height: rem(10px);
                    }
                }

                .list-group-item {
                    padding-top: 0;

                    &:not(:last-of-type) {
                        margin-bottom: $spacer;
                        padding-bottom: $spacer;
                    }

                    &:first-of-type {
                        margin-top: 0;
                        padding-top: 0;
                    }

                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }

                .product-line-item {
                    display: grid;
                    grid-gap: $grid-gutter-width;
                    grid-template-columns: minmax(auto, rem(130px)) 1fr;

                    .product-info {
                        > div:nth-child(2) {
                            margin: 2.25rem 0 1.25rem;
                        }

                        .line-item-title {
                            p {
                                font-size: $font-size-xs;
                                line-height: $line-height-lg;
                                margin: 0;

                                strong {
                                    color: $black;
                                }
                            }
                        }

                        .line-item-summary {
                            > div {
                                align-items: center;
                                display: flex;
                            }

                            .attribute-item {
                                .label {
                                    line-height: unset;
                                    margin-right: $spacer / 2;
                                }
                            }

                            .summary-item {
                                justify-content: space-between;

                                span {
                                    &:not(.label) {
                                        font-weight: $font-weight-prebold;
                                    }
                                }
                            }

                            span {
                                &:not(.label) {
                                    font-size: $font-size-xs;
                                }
                            }

                            hr {
                                border-top-color: $cart-grey;
                                margin-top: map-get($spacers, 3);
                                margin-bottom: map-get($spacers, 3);
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-cvv {
    p {
        color: $black;
        font-size: $font-size-xs;
    }

    @include media-breakpoint-up(sm) {
        max-width: rem(500px);
    }

    @include media-breakpoint-down(xs) {
        width: 70%;
    }

    .modal-body {
        padding: $modal-inner-padding-xl;

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }

        .card-cvv {
            width: auto;
            height: rem(80px);
            background-size: contain;
            background-repeat: no-repeat;

            &.mastercard {
                background-image: url('/assets/images/mastercard.png');
            }

            &.amexcard {
                background-image: url('/assets/images/amex.png');
            }
        }

        .customBorder::after {
            display: block;
            content: "";
            border-left: rem(1px) solid $gray-500;
            border-bottom: none;
            position: absolute;
            height: 70%;
            right: 0%;
            top: 28%;
            width: auto;
            left: auto;
            margin: 0;
        }

        @include media-breakpoint-down(xs) {
            .customBorder::after {
                border-bottom: rem(1px) solid $gray-500;
                border-left: none;
                position: relative;
                height: rem(2px);
                width: 100px;
                right: auto;
                left: rem(10px);
                top: 0;
                margin: rem(25px) 0 rem(20px);
            }
        }
    }
}

.address-edit-modal {
    .dropdown-item {
        &:hover {
            color: $white;
        }
    }
}

.address-validate-modal {
    @include media-breakpoint-up(sm) {
        max-width: rem(500px);
    }
}
