@import "./components/productFit";

@mixin gradient-x($start-color, $end-color) {
  background-image: linear-gradient(to right, $start-color 0%, $end-color 100%);
}

.product-info {

  &.disabled {
    border: 0.5rem solid red!important;
  }
}

.select-search-wrap {
  label.custom-select {
      overflow:hidden;
      padding-top: 0.9rem;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      
      &:hover {
          cursor: pointer;
      }
  }

  div {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      border: 1px solid rgb(221, 221, 221);
      border-top:none;
  }

  *.hidden {
      display: none;
  }

  ul {
      margin: 0;
      padding: 0;

      li {
          list-style: none;
          white-space: normal;
          cursor: pointer;
          padding: 0.5rem 0.3rem;
          margin: 0;
          line-height: 1.3;

          &:hover {
              background-color: lightskyblue;
          }

          &.hidden {
              display: none;
          }
      }
  }

  .innerselectSearchwrapper{
    border-radius: 0.55rem;
    overflow: hidden;
    border: 1px solid rgb(221, 221, 221);

    .custom-select{
      margin-bottom: 0;
      border-radius: 0.5rem 0.5rem 0 0;
      
      border: none;
    }
    .custom-input-wrapper{
      border: none;
      border-top: 1px solid rgb(221, 221, 221);
    }
    #pbe-selector{
      margin: auto;
      border-top: 1px solid rgb(221, 221, 221);
      width: 95%;
      border-radius: rem(0px);
      height: rem(35px);
      margin-top: 0.5rem;
    }
    .options-list {
      margin-top: rem(5px);
      max-height: rem(200px);
      overflow: auto;
      padding-bottom: rem(15px);
      border: none;
    }
  }
}

.pbeSelectionWrap {
  .pbe-select-title {
    font-size: 0.75rem;
    letter-spacing: 1.2px;
    line-height: 1.75;
  }

  .pbe-filter {
    height: rem(44px);
  }

  &.hidden, *.hidden {
    display: none;
  }

  .pbeSelection {
      transition: all ease-in-out 0.15s;

      .list {
          max-height: 160px;
          overflow-y: hidden;
          overflow-x: hidden;

          &.scroll {
              overflow-y: auto;
          }
      }
  }

  .pbeSelectionToggle {    
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .pbeSelectionToggle, .pbeSelectionToggle > * {
      cursor: pointer;
  }
}

.cart-area {
  .card {
    border-radius: 0.5rem;
  }

  h1 {
    &.page-title {
      font-size: 1.375rem;
      margin-bottom: rem(20px);

      span {
        margin-right: rem(5px);
      }

      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
        margin-bottom: rem(32px);
      }
    }
  }

  .card-font-1 {
    font-size: 1rem;
  }

  .card-ul {
    list-style: none;
    padding: 0;
    margin: 0;

    :first-child {
      @include media-breakpoint-down(sm) {
        //border-top: none !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
      }
    }
  }

  .product-img {
    background-color: $gray-400;

    img {
      width: 100%;
      height: auto;
    }
  }

  .font-weight-bold {
    font-weight: bold;
  }

  .card-link {
    color: $label-color;

    a {
      color: $label-color;

      &:hover {
        color: $label-color;
      }

      &:active {
        color: $label-color;
      }

      &:focus {
        color: $label-color;
      }
    }

    &.underline-link {
      a {
        text-decoration: underline;
      }
    }
  }

  .font-size-p8 {
    font-size: 0.8rem;
  }

  .font-size-p875 {
    font-size: 0.875rem;
  }

  .word-break {
    word-break: break-word;
  }

  .input-counter {
    max-width: rem(130px);
    text-align: center;
    display: inline-block;
    position: relative;

    span {
      position: absolute;
      top: 0;
      background-color: transparent;
      cursor: pointer;
      color: $label-color;
      width: rem(37px);
      height: 100%;
      line-height: rem(42px);
      transition: 0.5s;
      font-size: rem(14px);

      @include media-breakpoint-down(sm) {
        line-height: 2.5rem;
      }

      &.minus-btn {
        left: 0;

        &.disabled {
          background-color: $gray-400;
          border-top-left-radius: 0.375rem;
          border-bottom-left-radius: 0.375rem;
          border-top: 0.0625rem solid $gray-500;
          border-bottom: 0.0625rem solid $gray-500;
          border-left: 0.0625rem solid $gray-500;
          cursor: default;
        }
      }

      &.plus-btn {
        right: 0;
      }

      &:hover {
        color: #222;
      }
    }

    input {
      height: rem(40px);
      color: $label-color;
      outline: 0;
      display: block;
      border: none;
      text-align: center;
      width: 100%;
      font-size: rem(17px);
      font-weight: 600;
      border-radius: 0.375rem;
      border: 0.0625rem solid $gray-500;

      &::-webkit-input-placeholder {
        color: #222;
      }

      &:-ms-input-placeholder {
        color: #222;
      }

      &::-ms-input-placeholder {
        color: #222;
      }

      &::placeholder {
        color: #222;
      }
    }
  }

  .sidebar-card {
    background-color: $gray-400;
    border-radius: 0.5rem;
    padding: rem(18px) rem(20px);
    color: $label-color;
  }

  .card-header-custom {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1;
  }

  .sidebar-card__body {
    border-top: rem(1px) solid $cart-grey;
    margin-top: rem(15px);
    padding-top: rem(15px);
  }

  .icon {
    .icon-embroideryNeedle {
      background-image: $svg-embroideryNeedle;
    }
  }

  .w-m-auto {
    @include media-breakpoint-up(md) {
      width: auto !important;
    }
  }

  .line-item-name {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.38;

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
      line-height: 1.33;
    }
  }

  .tooltip-box {
    .tooltip-icon {
      background-image: $svg-tooltip;
      height: rem(18px);
      width: rem(18px);
      display: inline-block;
      cursor: pointer;

      .fade {
        display: none;
      }

      &:hover+.fade.tooltip {
        opacity: 1 !important;
        display: block;
      }
    }

    .tooltip {
      top: rem(-100px);
      left: rem(98px);

      .arrow {
        transform: rotate(-45deg);
        bottom: rem(-10px);
        left: rem(90px);
        z-index: 1;

        &::before {
          box-shadow: -0.1875rem 0.1875rem 0.5625rem 0 rgba(0, 0, 0, 0.15);
        }
      }

      .arrow,
      .arrow::before {
        background: $white;
        width: 1.25rem;
        height: 1.25rem;
      }

      .tooltip-inner {
        text-align: left;
      }
    }
  }

  .divider-top {
    margin-top: rem(-3px);
  }

  .embroidery_details {
    font-size: 0.875rem;
    margin-top: rem(15px);
    cursor: pointer;

    .collapse_div {
      display: flex;
      align-items: center;

      &::after {
        background-image: $svg-arrow-up;
      }
    }

    .expand_div {
      display: none;

      &::after {
        background-image: $svg-arrow-down;
      }
    }

    .collapse_div,
    .expand_div {
      &::after {
        content: "";
        display: block;
        display: block;
        height: rem(6px);
        width: rem(10px);
        margin-left: rem(5px);
        background-repeat: no-repeat;
      }

      span {
        text-decoration: underline;
      }
    }

    &.active {
      .expand_div {
        display: flex;
        align-items: center;
      }

      .collapse_div {
        display: none;
      }
    }
  }

  .cart-order-summary {
    .order-summary-costs-list {
      p {
        line-height: rem(20px);
        margin-bottom: rem(10px);
      }
    }

    .estimate-total {
      border-top: 1px solid $cart-grey;
      border-bottom: 1px solid $cart-grey;
      color: $black;
      font-size: 1.125rem;
      font-weight: 700;
      margin-top: rem(17px);
      margin-bottom: rem(17px);
      padding: 11px 0;
    }

    .shipping-progress-bar {
      margin: 0 auto;

      @include media-breakpoint-up(llg) {
        max-width: 75%;
      }

      .progress {
        background-color: $gray-400;
        border: solid 1px $gray-500;
        box-shadow: none;

        .progress-bar {
          @include gradient-x($sustainable, #63d58f);

          background-color: #63d58f;
          border-radius: 0.625rem;
        }
      }
    }
  }

  .links-wrapper {
    a {
      font-size: rem(12px);
      text-decoration: underline;
      margin: rem(12px);
    }
  }
}

.checkout-continue,
.next-step-button,
.place-order {
  position: static;

  @include media-breakpoint-down(md) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: rem(10px) rem(16px);
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
}

.desktop-show {
  display: block;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile-show {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.borderDiv {
  @include media-breakpoint-down(md) {
    border-bottom: 2px solid #ddd;
    width: 100%;
  }
}

.quick-view-dialog {
  max-width: rem(729px);

  .custom-quantity {
    .btn-secondary {
      padding: rem(14px) rem(25px);
    }
  }

  .product-quickview {
    .quickview-top {
      .style {
        font-size: 0.8125rem;
        color: $stylegey;
        text-transform: uppercase;
      }

      .product-name {
        margin-bottom: 0;
        font-size: rem(22px);
      }

      .price {
        font-size: 1.375rem;
        margin: 10px 0;
        color: $black;
        font-weight: 600;
        line-height: 1;

        @include media-breakpoint-up(md) {
          font-size: 1.625rem;
        }
      }
    }
  }

  .modal-header {
    .modal-title {
      display: none;
    }
  }

  .modal-body {
    padding-top: rem(10px);
    min-height: rem(300px);

    @include media-breakpoint-up(md) {
      padding: 0 rem(50px) rem(40px);
    }
  }

  .modal-content {
    &:first-child {
      .position-absolute {
        z-index: 3 !important;
        border-radius: 0.75rem;
      }
    }
  }

  .primary-images {
    @include media-breakpoint-up(md) {
      max-width: rem(272px);
    }

    .slide {
      background-color: $gray-400;
      position: relative;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
      }
    }
  }

  .detail-panel {
    @include media-breakpoint-up(md) {
      width: rem(349px);
    }

    @include productFit;

    .attribute {
      margin-bottom: rem(10px);
    }

    .color {
      &.non-input-label {
        &:first-of-type {
          margin-bottom: 8px;
        }
      }
    }

    .btn-dropdwon-swatches {
      &:focus {
        box-shadow: none !important;
      }
    }

    .dropdown-swatches {
      background-clip: border-box;
      max-height: rem(300px);
      overflow-y: auto;
      width: 100%;
    }

    .dropdown {
      .swatch-circle {
        height: rem(24px);
        width: rem(24px);

        &.swatch-white {
          border: solid 1px $gray-700;
        }
      }
    }

    .size-attribute-title {
      &.label {
        font-size: 0.75rem;
        letter-spacing: 1.2px;
        line-height: 1.75;

        span {
          font-weight: normal;
        }
      }
    }

    .spacer-right {
      margin-right: 10px;
    }
  }

  .product-description select {
    background-color: #f6f6f6 !important;
    font-size: 0.875rem;
  }

  .product-description {
    .dropdown-item {

      &:focus,
      &:hover {
        background-color: $gray-200;
        color: $black;
      }
    }
  }

  .size-attribute-content {
    grid-gap: 0.5rem;
    display: grid;
    flex-wrap: wrap;
    gap: 0.5rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;

    button {
      background-color: #f6f6f6;
      padding: 0.6875rem 0.3125rem;
      position: relative;
      width: 100%;

      &:not(:disabled):not(.disabled).active {
        border-color: $black;
      }

      &:not(:disabled):not(.disabled):focus {
        background-color: #f6f6f6 !important;
        box-shadow: inherit !important;
        color: inherit !important;
      }

      &.disabled {
        color: #a6a6a6;
        overflow: hidden;
        position: relative;

        &:before,
        &:after {
          border-top: 1px solid #a6a6a6;
          content: "";
          left: 50%;
          position: absolute;
          top: 50%;
          -webkit-transform: rotate(-33deg) translate(-50%, -50%);
          transform: rotate(-33deg) translate(-50%, -50%);
          -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
          width: 100%;
        }

        &:after {
          -webkit-transform: rotate(33deg) translate(-50%, -50%);
          transform: rotate(33deg) translate(-50%, -50%);
        }
      }
    }

    &.unselected {
      button:not(.disabled) {
        border: 0.0625rem solid #d0011b;
      }
    }
  }
}

.sticky-checkout ~ .footer-secondary {
  padding-bottom: 12rem;
}
