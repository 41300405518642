#homepage {
    &.content-page {
        @include media-breakpoint-up(md) {
            padding-top: rem(50px);
        }

        .home-banner-html {
            a {
                * {
                    pointer-events: none;
                }
            }
        }
    }
}
