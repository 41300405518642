@import "../utilities/swatch";
//@import "quickView";

.product-carousel {
    img {
        width: 100%;
    }
}

.product-tile {
    border: 0;
    margin-bottom: 0;

    .product-tile-body {
        font-size: rem(16px);
        color: $black;
        line-height: 1;
        padding: rem(20px) rem(16px) 0;

        .pdp-link {
            color: $plp-product-name-link-color !important;
            line-height: 1.2;
            text-decoration: none;
        }

        .product-price,
        .product-style,
        .color-swatches {
            margin-top: rem(12px);
        }

        .product-price {
            font-weight: $font-weight-prebold;

            &.sale {
                color: $plp-promo-color;
            }
        }
    }

    .product-image {
        background-color: $gray-400;
        position: relative;
        display: flex;
        align-items: center;

        &::before {
            content: "";
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: (800 / 536) * 100%;
        }

        &::after {
            content: "";
            display: table;
            clear: both;
        }

        a {
            display: block;
            width: 100%;

            .tile-image {
                width: 100%;
            }
        }
    }

    .color-swatches {
        display: flex;
        flex-wrap: wrap;
        gap: rem(8px);

        .swatch-circle {
            @include swatch(28px, $white);
    
            @include media-breakpoint-up(md) {
                width: 20px;
                height: 20px;
            }
        }
    }
}
