#mobile-filters-wrap {
    .filter-bar-mobile {
        height: calc(100% + rem(4px));
        margin-top: rem(-4px);
        margin-left: rem(8px);
        margin-right: -1rem;
        width: calc(100% - 2rem);

        &:not(.slick-initialized) {
            padding-right: 1rem;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
    
            .btn {
                margin-top: rem(4px);
                margin-right: rem(8px);
    
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        &.slick-initialized {
            .slick-list {
                transition: all .25s;
            }

            .slick-slide {
                padding-top: rem(4px);
                padding-right: rem(8px);
    
                &:last-of-type {
                    padding-right: 0;
                }
            }
    
            .slick-prev,
            .slick-next {
                background: transparent;
                border-radius: 0;
                height: 100%;
                opacity: 1 !important;
                padding-top: rem(4px);
                z-index: 1;

                &::before {
                    content: '';
                    border-color: $black;
                    border-style: solid;
                    border-width: 1px 1px 0 0;
                    display: inline-block;
                    position: relative;
                    height: rem(6px);
                    width: rem(6px);
                }
    
                &::after {
                    content: '';
                    background: $white;
                    border: solid 1px $gray-600;
                    border-radius: 50%;
                    position: absolute;
                    top: calc(50% - #{rem(8px)});
                    height: rem(20px);
                    width: rem(20px);
                    z-index: -1;
                }
    
                &.slick-disabled {
                    display: none !important;
                }
            }
    
            .slick-prev {
                left: 0;
                width: rem(28px);
    
                &::before {
                    left: rem(5px);
                    transform: rotate(-135deg);
                }
    
                &::after {
                    right: 0;
                }
            }
    
            .slick-next {
                right: 0;
                width: rem(36px);
    
                &::before {
                    right: rem(9px);
                    transform: rotate(45deg);
                }
    
                &::after {
                    left: 0;
                }
            }
        }

        .btn {
            border-color: $gray-600;
            font-weight: $font-weight-normal;
            letter-spacing: 0;
            min-width: rem(46px);
            padding: rem(9px) rem(10px) !important;
            position: relative;
            text-align: center;
            text-transform: capitalize;
    
            .selected-filters {
                max-width: 32ch;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
    
            span:first-of-type:not(:only-child) {
                background-color: $white;
                font-size: rem(10px);
                font-weight: $font-weight-prebold;
                line-height: 1;
                padding: 0 rem(12px) 0 rem(3px);
                position: absolute;
                left: rem(4px);
                top: rem(-5px);
    
                &::after {
                    content: '';
                    display: inline-block;
                    border-style: solid;
                    border-width: 1px 1px 0 0;
                    border-color: $black;
                    width: rem(6px);
                    height: rem(6px);
                    position: absolute;
                    transform: rotate(45deg);
                    right: rem(4px);
                    top: rem(2px);
                }
            }
    
            b {
                font-weight: $font-weight-prebold !important;
            }
    
            b:not(:last-of-type)::after {
                content: ',';
                margin-right: rem(2px);
            }
    
            &.active,
            &:active,
            &:focus,
            &:hover {
                border-color: $black;
                text-decoration: none;
            }

            > * {
                pointer-events: none;
            }
        }
    }

    .slick-arrow.slick-prev:not(.slick-disabled) + .slick-list {
        padding-left: rem(33px);
    }

    .btn-filters-mobile {
        border-color: $gray-600;
        height: rem(40px);
        padding: 0 rem(10px);
        text-align: center;
        width: rem(40px);

        &:hover,
        &:active {
            border-color: $black;
        }

        .icon[class*="icon-filter"] {
            display: block;
            height: 100%;
            margin: 0 auto;
            pointer-events: none;
            width: rem(18px);
        }
    }

    &.is-sticky {
        padding-left: 1rem;
        padding-right: 1rem;
        position: fixed;
        top: 0;
        z-index: 100;
        padding-top: rem(82px);
        padding-bottom: rem(10px);
        margin-left: rem(-16px);
        background-color: $white;
        box-shadow: $shadow2;
        
    }
}

.products-filter-options {
    @include media-breakpoint-down(sm) {
        padding: rem(16px) 0;
    }

    &.is-sticky {
        padding-left: 1rem;
        padding-right: 1rem;
        position: fixed;
        top: 0;
        z-index: 100;
        padding-top: rem(82px);
        padding-bottom: rem(10px);
        margin-left: rem(-16px);
        background-color: $white;
        box-shadow: $shadow2;
        
    }
}

.products-filter-options {
    @include media-breakpoint-down(sm) {
        padding: rem(16px) 0;
    }

    &.is-sticky {
        padding-left: 1rem;
        padding-right: 1rem;
        position: fixed;
        top: 0;
        z-index: 100;
        padding-top: rem(82px);
        padding-bottom: rem(10px);
        margin-left: rem(-16px);
        background-color: $white;
        box-shadow: $shadow2;
        
    }
}

.products-filter-options {
    @include media-breakpoint-down(sm) {
        padding: rem(16px) 0;
    }

    .result-count {
        color: $gray-800;
        position: relative;
        font-size: rem(14px);

        .filterby {
            display: none;
    
            @include media-breakpoint-up(md) {
                display: inline-block;
                font-weight: $font-weight-bold;
                color: $black;
                padding-right: map-get($spacers, 2);
                margin-right: map-get($spacers, 2);
                border-right: rem(1px) solid $gray-500;
            }
        }
    }

    .result-sort {    
        .form-group label {
            color: $gray-800;
            font-weight: $font-weight-base;
            letter-spacing: normal;
            text-transform: capitalize;
        }

        .tiles-grid-style {
            display: block;
            opacity: 0.31;
            margin-left: 8px;
    
            &:focus {
                box-shadow: none !important;
            }
    
            &.active {
                opacity: 1;
            }
    
            .icon {
                height: rem(20px);
            }
    
            .icon-3col {
                width: rem(29px);
            }
    
            .icon-4col {
                width: rem(38px);
            }
    
            .icon-5col {
                width: rem(48px);
            }
        }

        .plp-sort-select {
            background-color: $btn-main-color;
            font-size: rem(14px);
            height: rem(40px);
            margin-right: rem(12px);
            min-width: rem(275px);

            &:focus {
                box-shadow: none !important;
            }
        }
    }

    .tiles-grid-selector-mobile {
        gap: rem(17px);
        position: relative;

        &::after {
            background-color: $gray-500;
            content: '';
            display: block;
            height: rem(26px);
            left: rem(25px);
            position: absolute;
            width: 1px;
        }

        .tiles-grid-style {
            background-color: $white;
            border-radius: 0;
            box-shadow: none !important;
            display: flex;
            height: rem(14px);
            width: rem(17px);

            &.btn-1col {
                background-color: $gray-600;
            }

            &.btn-2col {
                &::before,
                &::after {
                    background-color: $gray-600;
                    content: '';
                    display: block;
                    height: 100%;
                    width: rem(7px);
                }

                &::before {
                    margin-right: rem(3px);
                }
            }

            &.active {
                &.btn-1col {
                    background-color: $gray-800;
                }

                &.btn-2col {
                    &::before,
                    &::after {
                        background-color: $gray-800;
                    }
                }
            }
        }

        .spacer {
            background-color: $gray-500;
            height: rem(26px);
            margin: 0 map-get($spacers, 2);
            width: 1px;
        }
    }
}

.refinements-mobile {
    background-color: $white;
    box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0);
    display: flex !important;
    flex-direction: column !important;
    height: 100%;
    left: 100%;
    padding: 0 !important;
    position: fixed;
    top: 0;
    transition-property: left;
    transition-duration: 425ms;
    transition-timing-function: cubic-bezier(0.71, 0, 0.55, 0.99);
    width: 100%;
    z-index: 102;

    &.show {
        box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
        left: 0;

        &::before {
            content: '';
            background-color: $black;
            height: 100%;
            position: fixed;
            opacity: 0.5;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    .refinements,
    .refinements-header,
    .refinements-footer {
        background-color: $white;
    }

    .refinements {
        overflow-y: auto;
        flex-grow: 1;
        padding: 0;
    }

    #refinements-wrapper {
        padding: 0 rem(16px);

        .refinement {
            display: none;
        }

        &:not(.all-filters) {
            .refinement {
                padding-top: 1.5rem;
            }

            .card-header {
                display: none !important;
            }

            .card-body {
                max-height: none;
                overflow-y: unset;
                padding: 0;                
            }

            .collapse {
                &:not(.show) {
                    display: block;
                }
            }
        }
    }

    .refinements-header,
    .refinements-footer {
        background-color: $white;
        border-color: $gray-500;
        border-style: solid;
        position: relative;
    }

    .refinements-header {
        border-width: 0 0 1px 0;
        padding: rem(10px) rem(16px);

        h2 {
            font-size: rem(16px);
            font-weight: $font-weight-bold;
            text-align: center;
            margin-bottom: 0;
        }

        .close {
            height: rem(14px);
            position: absolute;
            left: rem(16px);
            top: calc(50% - #{rem(8px)});

            span {
                height: rem(14px);
            }
        }
    }

    .refinements-footer {
        border-width: 1px 0 0 0;
        padding: rem(16px);
    }
}

.mobile-refinement-wrap {
    .yourselection {
        @include media-breakpoint-up(md) {
            text-transform: uppercase;
            font-size: rem(14px);
            margin-bottom: map-get($spacers, 3);
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            margin: 0 .5rem .5rem 0;

            &.filter-value {
                position: relative;

                button {
                    color: $black;
                    background: $gray-300;
                    padding: .25rem 1.5625rem .25rem .75rem;
                    border-radius: .375rem;
                    font-size: .875rem;
                    font-weight: 600;
                    height: 2.3125rem;
                    position: relative;

                    &::after {
                        content: "";
                        width: .625rem;
                        height: .625rem;
                        margin: 0;
                        display: inline-block;
                        background: $svg-close no-repeat 0 0;
                        position: absolute;
                        right: .625rem;
                        top: calc(50% - .25rem);
                    }
                }
            }
            
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.refinements {
    @include media-breakpoint-down(sm) {
        .result-count {
            color: $gray-800;
            line-height: 1;
            padding: rem(16px) 0;
            position: relative;
        }
    }

    @include media-breakpoint-up(md) {
        max-width: rem(384px);
    }

    .refinement {
        &.card {
            .card-body {
                padding: 1px 0 !important;
                margin-bottom: rem(24px);
                overflow-y: auto;
                max-height: rem(231px);
        
                @include media-breakpoint-up(md) {
                    max-height: rem(322px);
                }

                ul {
                    margin-bottom: 0;

                    li {
                        &, label {
                            font-size: $font-size-base;
                            line-height: rem(20px);
                            margin-bottom: 0;
    
                            @include media-breakpoint-up(md) {
                                font-size: $font-size-xs;
                            }
                        }
                    }

                    li {
                        .form-group {
                            margin: 0;
                        }

                        button {
                            color: $dark;
                            padding: 0;
                            font-size: $font-size-base;
                            text-align: left;
                            position: relative;
        
                            @include media-breakpoint-up(md) {
                                font-size: $font-size-xs;
                            }
                        }

                        + li:not(.item-c_color):not(.item-c_size) {
                            margin-top: rem(10px);
                        }
                    }
                }
            }
        }

        &.refinement-c_size,
        &.refinement-c_color {
            ul {
                display: grid;
                gap: rem(8px);
                grid-template-rows: 1fr;
                padding-right: rem(8px);

                .custom-control {
                    padding: 0;
                }

                .custom-checkbox {
                    .custom-control-label {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &.refinement-c_size {
            ul {
                grid-template-columns: repeat(3,1fr);

                .custom-checkbox {
                    .custom-control-label {
                        display: block;
                        padding: rem(13px) rem(5px);
                        text-align: center;

                        &::before {
                            background-color: $gray-400;
                            border-color: $gray-400;
                            border-radius: $btn-border-radius;
                            height: 100%;
                            left: 0;
                            width: 100%;
                            z-index: -1;
                        }
                    }

                    .custom-control-input {
                        height: 100%;
                        top: 0;
                        width: 100%;
    
                        &:checked {
                            ~ .custom-control-label {    
                                &::before {
                                    border-color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.refinement-c_color {
            ul {
                grid-template-columns: repeat(2,1fr);

                li {
                    padding-bottom: rem(4px);
                }

                .custom-control {
                    align-items: center;
                    display: flex;
                    gap: rem(4px);
                }

                .custom-control-input {
                    appearance: none;
                    border: none;
                    border-radius: 50%;
                    cursor: pointer;
                    flex: none;
                    height: rem(28px);
                    opacity: 1;
                    outline: none;
                    position: relative;
                    left: unset;
                    top: unset;
                    width: rem(28px);
                    z-index: 0;

                    &:checked {
                        &::after {
                            position: absolute;
                            top: calc(50% - #{rem(5px)});
                            left: calc(50% - #{rem(5px)});
                            right: auto;
                            bottom: auto;
                            content: "";
                            border: 2px solid $white;
                            border-radius: 0;
                            border-width: 0 0 2px 2px;
                            width: rem(11px);
                            height: rem(6px);
                            transform: rotate(-45deg);
                        }
                    }

                    &.swatch-white {
                        border: solid 1px $gray-700;

                        &:checked {
                            border-color: $black;
                        }
                    }
                }

                .custom-checkbox {
                    .custom-control-label {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.plp-grid-column {
    @include media-breakpoint-up(llg) {
        &:not(.plp-grid-fullwith) {
            padding-left: 50px;
        }
    }

    .product-grid {
        @include media-breakpoint-down(sm) {
            &.row-cols-2 {
                margin-left: -$grid-gutter-width / 2 !important;
                margin-right: -$grid-gutter-width / 2 !important;

                .product-tile-column {
                    &:nth-child(odd) {
                        padding-left: 0;
    
                        .tile-body {
                            padding-left: $grid-gutter-width / 2;
                        }
                    }
    
                    &:nth-child(even) {
                        padding-right: 0;
    
                        .tile-body {
                            padding-right: $grid-gutter-width / 2;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-left: rem(-6px);
            margin-right: rem(-6px);

            .product-tile-column {
                padding-left: rem(6px);
                padding-right: rem(6px);
            }
        }

        .product-tile-column {
            transition: max-width 0.25s ease-in-out, flex 0.25s ease-in-out;
    
            img {
                max-width: 100%;
            }
    
            padding-bottom: rem(40px);
        }
    }
}

/*
.refinement {
    .card-body {
        padding: 1px 0 !important;
        margin-bottom: 24px;
        overflow-y: auto;
    }

    .form-check {
        cursor: pointer;

        label {
            margin-left: 10px;
            font-weight: 400;
        }

        input[type="checkbox"] {
            width: 20px;
            height: 20px;
            accent-color: black;
        }
    }

    .btn-link {
        span {
            font-size: .875rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .refinement {
        .card-body {
            max-height: 320px;
        }

        .form-check {
            label {
                font-size: .875rem;
            }
        }
    }
}
*/
