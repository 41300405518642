// Wrapper for the tooltip content
.tooltip {
    border-radius: $tooltip-border-radius;
    box-shadow: $tooltip-shadow;
    line-height: $tooltip-line-height;
    margin-bottom: map-get($spacers, 4);
    padding: 0 !important;
    z-index: 1040;

    .tooltip-inner {
        position: relative;
        text-align: $tooltip-text-align;
        z-index: 2;
    }

    .arrow {
        z-index: 1;

        &::before {
            background: $tooltip-bg;
            width: $tooltip-arrow-width;
            height: $tooltip-arrow-width;
        }
    }
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    .arrow {
        transform: rotate(-45deg);
        bottom: -10px;

        &::before {
            box-shadow: rem(-3px) rem(3px) rem(9px) 0 rgba(0, 0, 0, 0.15);
        }
    }
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    .arrow {
        transform: rotate(135deg);
        top: -10px;

        &::before {
            box-shadow: 0 0 rem(4px) 0 rgba(0, 0, 0, 0.15);
        }
    }
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    .arrow {
        transform: rotate(225deg);
        right: -10px;

        &::before {
            box-shadow: rem(-3px) 0 rem(9px) 0 rgba(0, 0, 0, 0.15);
        }
    }
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    .arrow {
        transform: rotate(45deg);
        left: -10px;

        &::before {
            box-shadow: 0 rem(3px) rem(9px) 0 rgba(0, 0, 0, 0.15);
        }
    }
}
